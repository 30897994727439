import React from "react";

import { BuySellContainer, Title, Buttons, Button } from "./styles";

const JoinToDecentralization = () => {
  return (
    <BuySellContainer>
      <Title>Súmate a la descentralización de las inversiones</Title>
      {/* <Buttons>
        <Button theme={{color: '#f58634'}}>comprar</Button>
        <Button theme={{color: '#c99e49'}}>vender</Button>
      </Buttons> */}
      {/* <TextContainer>
      </TextContainer> */}
    </BuySellContainer>
  );
};

export default JoinToDecentralization;

import React from "react";
import CompraVentaActivos from "./CompraVentaActivos";
import PlatformContract from "./PlatformContract";
import TreatmentContract from "./TreatmentContract";
import TarjetaPrepago from "./TarjetaPrepago";
import AsociationContract from "./AsociationContract";

function AllInOne() {
  return (
    <div>
      <PlatformContract />
      <br />
      {/* <TarjetaPrepago />
      <br />
      <TreatmentContract />
      <br />
      <CompraVentaActivos />
      <br />
      <AsociationContract /> */}
    </div>
  );
}

export default AllInOne;

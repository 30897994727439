import styled from "styled-components";
import colors from "styles/common/colors";
import { Icon } from "@iconify/react";

const TeamMemberDescription = styled.h5`
  font-family: "Bahnschift Light", sans-serif;
  font-size: 18px;
  margin: 0;
  /* color: ${colors.feanor_dark_bg}; */
  color: ${(props) => props.textColor};
  font-weight: 300;
  text-align: center;
  margin-top: 25px;
  @media screen and (max-width: 640px) {
    margin-top: 10px;
    font-size: 13px;
    font-weight: 200;
    margin-right: 0px;
  }

  @media screen and (max-width: 320px) {
    margin-top: 10px;
    font-size: 13px;
  }
`;

const TeamMemberPosition = styled.h5`
  font-family: "Bahnschift Light", sans-serif;
  text-shadow: -1px -1px 0 black, 1px -1px 0 black, -1px 1px 0 black,
    1px 1px 0 black; /* Simula un borde blanco alrededor de la letra */
  font-size: 22px;
  font-weight: normal;
  margin: 0;
  color: ${colors.flexbit_mustard};
  font-weight: 700;
  margin-top: 20px;
  text-align: center;
  @media screen and (max-width: 640px) {
    font-size: 13px;
  }
`;

const ImageMember = styled.img`
  max-width: 100%;
  height: 97px;
  /* border-radius: 50%; */
`;

export const ContainerTeamMember = styled.div`
  box-sizing: border-box;
  padding: 20px;
  width: 300px;
  min-height: 300px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  @media (max-width: 1000px) {
    flex-wrap: 32%;
  }
  @media screen and (max-width: 640px) {
    width: 100%;
  }
`;

export const ContainerMembers = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  @media screen and (max-width: 640px) {
    flex-direction: column;
  }
`;

export const StyledIcon = styled(Icon)`
  font-size: 80px; /* Ajusta el tamaño del icono */
  color: ${colors.flexbit_black};
  padding: 15px; /* Ajusta el padding */
  border-radius: 50%;
  background-color: ${colors.flexbit_mustard};
`;

export default {
  TeamMemberDescription,
  TeamMemberPosition,
  ImageMember,
  StyledIcon,
  ContainerTeamMember,
  ContainerMembers,
};

import styled from "styled-components";
import colors from "styles/common/colors";
import { className } from "styled-components";
import { Link } from "react-router-dom";
import { HashLink } from "react-router-hash-link";
import { Icon } from "@iconify/react";

const ContainerFooter = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: ${colors.flexbit_gray_bg};
  color: black;
  /* @media screen and (max-width: 640px) {
    padding-bottom: 300px;
  } */
`;

const ContainerSectionFooter = styled.div`
  display: flex;
  flex-direction: column;
`;

const FooterNav = styled.div`
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  justify-content: space-between;
  /* border-top: 1px solid rgba(117, 117, 117, 0.5); */
  box-sizing: border-box;
  padding: 3rem 10rem;
  background: transparent;
  @media screen and (max-width: 1280px) {
    align-items: center;
    padding: 3rem 5rem;
  }
  @media screen and (max-width: 1024px) {
    justify-content: space-around;
    align-items: start;
    gap: 16px;
  }
  @media screen and (max-width: 640px) {
    font-size: 12px;
    padding: 24px;
    flex-direction: column;
    align-items: center;
  }
`;

export const FooterCopy = styled.div`
  width: 100%;
  padding: 0 36px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  font-family: Montserrat;
  font-weight: bold;
  @media screen and (max-width: 640px) {
    font-size: 12px;
  }
`;

export const FooterAviso = styled.div`
  width: 80%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px 50px;
  font-size: 12px;
  display: none;
`;

export const FooterColum = styled.div`
  box-sizing: border-box;
`;

export const FooterHeading = styled.h3`
  font-size: 16px;
  text-transform: uppercase;
  font-weight: 600;
  margin-bottom: 25px;
  font-family: "Bahnschift Light", sans-serif;
  @media screen and (max-width: 640px) {
    font-size: 15px;
    margin-bottom: 16px;
    text-align: center;
  }
`;

export const FooterMenu = styled.ul`
  margin: 0;
  padding: 0;
  display: flex;
  font-family: "Bahnschift", sans-serif;
  flex-direction: column;
  @media screen and (max-width: 640px) {
    text-align: center;
  }
`;

export const FooterMenuMail = styled.a`
  margin: 0;
  padding: 0;
  display: flex;
  font-family: Montserrat;
  flex-direction: column;
  color: white;
  text-decoration: none;
  @media screen and (max-width: 640px) {
    text-align: center;
  }
`;

export const FooterSocial = styled.ul`
  position: absolute;
  right: 0px;
  bottom: 0px;
  width: 200px;
  margin: 0px;
  padding: 30px;
  padding-left: 80px;
  padding-top: 60px;
  height: 200px;

  list-style: none;
  flex: 0 1 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  border-radius: 300px 0px 0px 0px;
`;

export const LinkFooter = styled(Link)`
  color: black;
  margin-top: 10px;
  text-decoration: none;
  font-family: Montserrat;
  &:hover {
    opacity: 0.8;
  }
`;

export const SocialLinkFooter = styled.a`
  width:100%;
  padding: 4px 8px;
  background: ${colors.flexbit_mustard}
  margin-top: 10px;
  text-decoration: none;
  font-family: Montserrat;
  display: flex;
  gap: 8px;
  border-radius:8px;
  item-aligns: center;
  justify-content:center;
  cursor: pointer;
  &:hover {
    font-weight:bold;
    box-shadow: inset 0 0 0 1px black;
  }
`;

export const SpanFooter = styled.span`
  font-size: 16px;
  color: black;
`;

export const LinkHash = styled(HashLink)`
  color: black;
  margin-top: 10px;
  text-decoration: none;
  font-family: Montserrat;
  &:hover {
    opacity: 0.8;
  }
`;

export const ContainerSocialLinks = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 10px;
  margin-bottom: 10px;
  justify-content: space-between;
`;

export const companyDesc = styled.div`
  max-width: 250px;
  font-size: 14px;
  text-align: justify;
  @media (max-width: 767px) {
    max-width: 100%;
  }
`;
export const bold = styled.span`
  font-weight: bold;
`;

export const StyleIcon = styled(Icon)`
  font-size: 30px;
`;

export default {
  ContainerFooter,
  FooterNav,
  FooterCopy,
  FooterAviso,
  FooterColum,
  FooterHeading,
  FooterMenu,
  FooterSocial,
  SpanFooter,
  LinkFooter,
  ContainerSectionFooter,
  ContainerSocialLinks,
  LinkHash,
  SocialLinkFooter,
  FooterMenuMail,
  companyDesc,
  bold,
};

const colors = {
  blue_agente_btc : '#1A4BEA',
  yellow_agente_btc: '#FFC85C',
  ligth_yellow_agente_btc: "rgba(255, 200, 92, .4)",
  grey_agente_btc: '#FFC85C',
  dark_blue_agente_btc: '#3C4A5B',
  super_dark_blue_agente_btc: '#272C49',
  soft_grey: '#727D8A',
  feanor_orange: '#f58634',
  feanor_orange_light: '#F9B883',
  feanor_gold: '#c99e49',
  feanor_dark_bg:'#000000',
  // feanor_dark_bg:'#201E1F'
  //flexbit
  flexbit_violet_bg:'#130631',
  flexbit_gray_bg:'#E8E7DF',
  flexbit_mustard:'#FDC22C',
  flexbit_black:'#000000',
  flexbit_green:'#4DAF95',
}

export default colors;

import styled from "styled-components";
import bgBuySell from "./../../media/bg/bg-buy-sell.jpg";
import colors from "styles/common/colors";

export const BuySellContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  width: 100%;
  margin: auto;
  flex-wrap: wrap;
  background-color: ${colors.flexbit_gray_bg};
  background-repeat: no-repeat;
  background-size: 100% 100%;
`;
export const Title = styled.div`
  font-family:sans-serif;
  color: black;
  /* font-weight: 600; */
  font-size: 26px;
  text-align: center;
  @media (max-width: 640px) {
    font-size: 17px;
  }
  @media (max-width: 330px) {
    font-size: 17px;
  }
`;

export const Buttons = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  /* margin-left: 20px; */
`;

export const Button = styled.a`
  margin-left: 10px;
  margin-bottom: 5px;
  text-transform: uppercase;
  font-weight: bold;
  color: black;
  font-size: 16px;
  border-radius: 25px;
  text-align: center;
  width: 100px;
  background: ${(props) => props.theme.color};
  cursor: pointer;
  padding: 8px 25px;
  transition: 0.4s all;
  &:hover {
    /* background: rgba(0, 0, 0, 0.01); */
  }
`;

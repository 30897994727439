import React, { useEffect } from "react";
import { withRouter } from "react-router";

import { PrimaryText, TitleText } from "styles/colorized";

import Styled from "./styles";
import {
  Header3,
  TitleSection,
  BlackContainer,
  TitleBlackSection,
  DescriptionBlackSection,
  SectionDividerWithTitle,
  DescriptionSteps,
  VioletContainer,
} from "styles/common/index.js";

import heroImg from "media/heros/otc_feanor.png";

import iconStep1 from "media/icons/paso1_otc_gold.png";
import iconStep2 from "media/icons/paso2_otc_gold.png";
import iconStep3 from "media/icons/icon_bitcoin_gold.png";
import iconStep4 from "media/icons/paso4_otc_gold.png";

import { Helmet } from "react-helmet";

import colors from "styles/common/colors";
import CryptoactivesCustody from "./HowToUse";

import { TopLine } from "styles/common";
import lineBottom from "media/lines/gold-line-bottom-shadow-outside.png";
import imgRecepcion from "media/icons/recepcion-pagos.png";
import imgRetiro from "media/icons/retiro-fondos.png";
import imgPlataforma from "media/icons/plataforma.png";
import imgAPI from "media/icons/api.png";
import TokenizationFeatures from "components/TokenizationFeatures";
const OTC = ({ history }) => {
  // Scroll to top on all pages
  const scrollTop = () => {
    window.scrollTo(0, 0);
  };

  useEffect(() => {
    scrollTop();
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <VioletContainer>
        <Styled.HeroSection>
          <Styled.HeroText>
            <Styled.TaglineAbout>
              <TitleText style={{ color: colors.flexbit_mustard }}>
                FlexBit Empresas
              </TitleText>
            </Styled.TaglineAbout>
            <Styled.SubtaglineHome>
              <PrimaryText style={{ color: "black" }}>
              {/* Te ayudamos a Tokenizar Activos y difundirlos a nivel global de una manera fácil y segura. */}
              Te ayudamos a Tokenizar Activos y difundirlos a nivel global de una manera fácil y segura.
              </PrimaryText>
            </Styled.SubtaglineHome>
          </Styled.HeroText>
          <Styled.HeroImgWrapper>
            <Styled.HeroImg src={heroImg} alt="Network" />
          </Styled.HeroImgWrapper>
        </Styled.HeroSection>       
      </VioletContainer>

     
      <div id="nuestros-servicios">
        <SectionDividerWithTitle>Nuestros servicios</SectionDividerWithTitle>
      </div>     

      <Styled.TeamContainer>        
        <Styled.ContainerTextTeam>
          <Styled.TaglineAbout>
            <TitleBlackSection>Tokenización de Activos</TitleBlackSection>
          </Styled.TaglineAbout>

          <TokenizationFeatures textColor="black" />
        </Styled.ContainerTextTeam>        
      </Styled.TeamContainer>      

      <Helmet>
        <meta charSet="utf-8" />
        <title> Empresas | FlexBit</title>
        <meta
          name="description"
          content="Servicio de altas inversiones de activos digitales como bitcoin para instituciones e inversores de alto patrimonio."
        />
      </Helmet>
    </>
  );
};

export default withRouter(OTC);

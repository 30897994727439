import styled from "styled-components";
import colors from "./common/colors";

export const TitleText = styled.span`
  color: ${colors.flexbit_mustard};
  
  /* color: ${colors.flexbit_mustard}; */
  font-family: "Bahnschift Light", sans-serif;
  text-shadow: 
   -1px -1px 0 white,  
   1px -1px 0 white,
   -1px 1px 0 white,
   1px 1px 0 white; /* Simula un borde blanco alrededor de la letra */
  box-sizing: border-box;
  line-height: 1.3;
  @media (max-width: 600px) {
    font-size: 19px;
  }
`;

export const PrimaryText = styled.span`
  color: ${colors.flexbit_mustard};
  
  /* color: ${colors.flexbit_mustard}; */
  font-family: "Bahnschift Light", sans-serif;
  box-sizing: border-box;
  line-height: 1.3;
  font-weight:bold;
   text-shadow: 
   -1px -1px 0 white,  
   1px -1px 0 white,
   -1px 1px 0 white,
   1px 1px 0 white;
  @media (max-width: 600px) {
    font-size: 19px;
  }
`;

export const SubPrimaryText = styled(PrimaryText)`
  margin: 0 auto 20px auto;
  color: white;
  padding: 10px;
  @media (max-width: 600px) {
    margin-top: 25px;
    text-align: center;
    font-size: 17px;
  }
`;

export const SecondaryText = styled.span`
  color: ${colors.flexbit_mustard};
  font-family: "Bahnschift Light";
  box-sizing: border-box;
  @media (max-width: 600px) {
    font-size: 19px;
  }
`;

import styled from "styled-components";
export const Center = styled.h5`
  text-align: center;
  font-size: 16px;
`;

export const Subtitle = styled.h6`
  text-align: left;
  font-size: 14px;
  text-transform: uppercase;
`;

export const Title = styled.h5`
  text-align: left;
  font-size: 18px;
  text-transform: uppercase;
  font-weight: bold;
  margin-bottom: 20px;
`;

export const Bold = styled.span`
  font-weight: 500;
`;

export const HiperLink = styled.a`
  font-size: 14px;
  text-decoration: underline;
  color: blue;
  cursor: pointer;
`;

export const Paragraph = styled.p`
  margin: 10px 0 20px 0;
  font-size: 14px;
  font-family: Montserrat, sans-serif;
  text-align: justify;
  font-weight: normal;
`;

export const Underline = styled.span`
  text-decoration: underline;
`;

export const ListNormal = styled.ul`
  font-family: Montserrat, sans-serif;
  font-size: 14px;
  list-style-type: disc;
  list-style-position: outside; // Marcadores fuera del flujo del texto
  font-weight: bold;
  padding-left: 20px; // Espacio para el marcador
  margin-left: 30px;
`;

export const ListNumber = styled.ol`
  font-family: Montserrat, sans-serif;
  font-size: 14px;
  list-style-type: decimal;
  list-style-position: outside; // Marcadores fuera del flujo del texto
  font-weight: bold;
  padding-left: 20px; // Espacio para el marcador
  margin-left: 20px;
`;

export const ItemList = styled.li`
  font-size: 14px;
  padding-left: 10px; // Espacio entre el marcador y el texto
  padding-top: 5px;
  padding-bottom: 5px;
  text-align: justify;
`;

export const ItemText = styled.span`
  font-weight: bold;
  font-size: 16px;
`;

export default {
  Center,
  Bold,
  Paragraph,
  Subtitle,
  Title,
  Underline,
  HiperLink,
  ListNormal,
  ListNumber,
  ItemList,
  ItemText,
};

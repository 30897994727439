import React, { useEffect, useState, useRef } from "react";
import "react-responsive-modal/styles.css";
// import Modal from "react-responsive-modal";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import {
  Modal,
  BodyModal,
  CloseBtn,
  TitleModal,
  LabelModal,
  LabelSpan,
  TextAreaMsg,
  InputModal,
  StyledButtonYellow,
  Background,
} from "./styles";

import swal from "sweetalert";
import swal2 from "sweetalert2";

const ContactForm = ({ openForm, onCloseForm }) => {
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  //   const modalRef = useRef();

  useEffect(() => {
    // document.addEventListener("mousedown", handleClickOutside);
    window.addEventListener("keydown", onEscKeyDown, false);

    return () => {
      //   document.removeEventListener("mousedown", handleClickOutside);
      document.removeEventListener("keydown", onEscKeyDown);
    };
  }, []);

  const closeForm = () => {
    onCloseForm();
    resetFields();
  };

  const resetFields = () => {
    setMessage("");
    setEmail("");
  };

  const onEscKeyDown = (e) => {
    // if (e.key !== "Escape") return;
    // onCloseForm();
  };
  //   const handleClickOutside = (e) => {
  //     if (modalRef.current && modalRef.current.contains(e.target)) {
  //       return;
  //     }
  //     console.log("outside");
  //     closeForm();
  //   };

  const handleSendEmail = () => {
    if (email == "") {
      swal({
        text: "Por favor ingrese su correo electrónico",
        button: true,
        timer: 5000,
      });
      return;
    }

    let regEmail = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (!regEmail.test(email)) {
      swal({
        text: "El email no es válido",
        button: true,
        timer: 5000,
      });
      return;
    }

    if (message == "") {
      swal({
        text: "Por favor ingrese su mensaje",
        button: true,
        timer: 5000,
      });
      return;
    }

    // TODO: Conectarse al API para enviar el correo

    swal2.fire(
      "Mensaje enviado",
      "Gracias! nos pondremos en contacto pronto",
      "success"
    );
  };

  const handleChangeEmail = (e) => {
    setEmail(e.target.value);
  };
  const handleChangeMessage = (e) => {
    setMessage(e.target.value);
  };

  return (
    <div>
      <Modal
        open={openForm}
        onClose={closeForm}
        onKeyPress={(e) => console.log(e)}
      >
        <Background onClick={closeForm} />
        <BodyModal>
          <TitleModal>¡Contáctanos ahora!</TitleModal>
          <TitleModal>
            Te responderemos de inmediato para ayudarte a dar el siguiente paso.
          </TitleModal>

          <CloseBtn icon={faTimes} onClick={closeForm} />
          <LabelModal>
            <LabelSpan>Dirección de su correo electrónico</LabelSpan>
            <InputModal
              type="text"
              value={email}
              onChange={(e) => handleChangeEmail(e)}
            />
          </LabelModal>

          <LabelModal>
            <LabelSpan>Contenido del mensaje</LabelSpan>
            <TextAreaMsg
              type="text"
              value={message}
              onChange={(e) => handleChangeMessage(e)}
            />
          </LabelModal>

          <StyledButtonYellow onClick={handleSendEmail}>
            Enviar
          </StyledButtonYellow>
        </BodyModal>
        {/* <BodyModal>
        <TitleModal>Enviar Bitcoin</TitleModal>

        

        <LabelModal>
          <LabelSpan>Dirección de la billetera destino</LabelSpan>
          <InputModal
            type="text"
            
           
          />
        </LabelModal>

        <LabelModal>
          
          <InputModal
            type="number"
            min="0"
           
          />
        </LabelModal>

        <Margin20></Margin20>

        <DetailsOperation>
          <DetailTitle>Monto a enviar</DetailTitle>
         
        </DetailsOperation>

        <Clear></Clear>

        <DetailsOperation>
          <DetailTitle>Comisión</DetailTitle>
          
        </DetailsOperation>

        <Clear></Clear>

        <DetailsOperation>
          <DetailTitle>Monto total</DetailTitle>          
        </DetailsOperation>

        <Margin20></Margin20>
        <Margin20></Margin20>


        <FooterModal>
          <b>Importante:</b> Asegúrese de introducir correctamente los datos de
          la dirección destino (lo ideal es copiar y pegar). FlexBit no se
          hace responsable de las monedas enviadas a una dirección equivocada.
        </FooterModal>
      </BodyModal> */}
      </Modal>
    </div>
  );
};

export default ContactForm;

import styled from "styled-components";
export const Center = styled.h5`
  text-align: center;
  font-size: 16px;
`;

export const Subtitle = styled.h6`
  text-align: left;
  font-size: 14px;
  text-transform: uppercase;
`;

export const Title = styled.h5`
  text-align: left;
  font-size: 16px;
  text-transform: uppercase;
`;

export const Bold = styled.span`
  font-weight: 500;
`;

export const HiperLink = styled.a`
  font-size: 14px;
  text-decoration: underline;
  color: blue;
  cursor: pointer;
`;

export const Paragraph = styled.p`
  margin: 10px 10px 20px 10px;
  font-size: 14px;
  font-family: Montserrat, sans-serif;
  text-align: justify;
`;

export const Underline = styled.span`
  text-decoration: underline;
`;

export const ListNormal = styled.ul`
  font-family: Montserrat, sans-serif;
  font-size: 14px;
  list-style-type: disc;
  list-style-position: outside; // Marcadores fuera del flujo del texto
  font-weight: bold;
  padding-left: 20px; // Espacio para el marcador
  margin-left: 30px;
`;

export const ListNumber = styled.ol`
  font-family: Montserrat, sans-serif;
  font-size: 14px;
  list-style-type: decimal;
  list-style-position: outside; // Marcadores fuera del flujo del texto
  font-weight: bold;
  padding-left: 20px; // Espacio para el marcador
  margin-left: 30px;
`;

export const ItemList = styled.li`
  font-size: 14px;
  padding-left: 10px; // Espacio entre el marcador y el texto
  padding-top: 5px;
  padding-bottom: 5px;
  text-align: justify;
`;

export const ItemText = styled.span`
  font-weight: normal; // Asegura que el texto no está en negrita
`;

// Estilos para la tabla
export const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  margin: 20px 0;
  font-size: 14px;
  font-family: Montserrat, sans-serif;

  @media (max-width: 768px) {
    display: block;
    overflow-x: auto;
    white-space: nowrap;
  }
`;

export const TableHeader = styled.th`
  background-color: #f4f4f4;
  padding: 10px;
  text-align: left;
  border: 1px solid #ddd;
`;

export const TableRow = styled.tr`
  &:nth-child(even) {
    background-color: #f9f9f9;
  }
`;

export const TableCell = styled.td`
  padding: 10px;
  border: 1px solid #ddd;
  text-align: center;
`;

export const LeyendTable = styled.p`
  margin-top: 2px;
  margin-bottom: 8px;
  font-family: Montserrat, sans-serif;
  font-size: 12px;
  text-align: center;
`;
export default {
  Center,
  Bold,
  Paragraph,
  Subtitle,
  Title,
  Underline,
  HiperLink,
  ListNormal,
  ListNumber,
  ItemList,
  ItemText,
  Table,
  TableHeader,
  TableRow,
  TableCell,
  LeyendTable,
};

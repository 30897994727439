import styled from "styled-components";

const AppContainer = styled.div`
  width: 100%;
  min-height: 100vh;
  // max-width: 1500px;
  background-color: white;
  box-sizing: border-box;
  margin:0 auto;
  padding: 0px;
  overflow: hidden;
  position: relative;
`;

export default {
  AppContainer
};

import React from "react";
import styled from "styled-components";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faTelegram } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

library.add(faTelegram);

const LinkWhastapp = styled.a`
  text-decoration: none;
  background-color: #259cf1;
  padding: 10px 30px;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.3s;
  border-radius: 40px;
  font-weight: bold;
  text-align: center;
  margin: auto;
  text-align: center;
  width: 149px;
  position: fixed;
  bottom: 10px;
  right: 10px;
  z-index: 100;
  &:hover {
    background-color: #2684c7;
    color: white;
  }
  &:visited {
    color: white;
  }

  @media (max-width: 640px) {
    width: auto;
    font-size: 13px;
    padding: 10px;
  }
`;

const SocialIcon = styled(FontAwesomeIcon)`
  color: white;
  font-size: 23px;
  margin-right: 10px;
  @media (max-width: 640px) {
    margin-right: 0px;
  }
`;

const Text = styled.span`
  @media (max-width: 640px) {
    display: none;
  }
`;

const TelegramButton = () => {
  return (
    <LinkWhastapp
      target="_blank"
      rel="noopener noreferrer"
      href="https://t.me/Flex_Bit"
      // href="https://api.whatsapp.com/send?phone=51998800769"
      // href="https://wa.me/51998800769?text=Me%20interesa%20in%20el%20auto%20que%20vendes"
    >
      <SocialIcon icon={faTelegram} />
      <Text> Contactar</Text>
    </LinkWhastapp>
  );
};

export default TelegramButton;

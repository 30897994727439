import React, { useContext, useState } from "react";
import { NavLink } from "react-router-dom";
import { AuthContext } from "Auth";
import app from "base";
import "./ToggleableNav.css";
import feanorapi from "feanorapi.js";

const ToggleableNav = ({ logout }) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const { currentToken, expiration } = useContext(AuthContext);

  if (isOpen) {
    return (
      <div className="nav-toggle nav-toggle--open show-mobile">
        <span className="nav-toggle__button" onClick={toggleMenu}></span>

        <nav className="nav__items">
          {/* <a
            rel="noopener noreferrer"
            href={`${feanorapi.webapp}/nft-market`}
            onClick={toggleMenu}
            class="nav__item"
          >
            NFT Market
          </a> */}

          <NavLink
            to="/wallet"
            exact
            activeClassName="selectedLink"
            className="nav__item"
            onClick={toggleMenu}
          >
            Billetera Personas
          </NavLink>

          {/* <NavLink
            to="/empresas"
            exact
            activeClassName="selectedLink"
            className="nav__item"
            onClick={toggleMenu}
          >
            Billetera Empresas
          </NavLink> */}

          {/* <NavLink
            to="/empresas#nuestros-servicios"
            exact
            activeClassName="selectedLink"
            className="nav__item"
            onClick={toggleMenu}
          >
            wallet crypto
          </NavLink> */}
          <NavLink
            to="/quienes-somos"
            exact
            activeClassName="selectedLink"
            className="nav__item"
            onClick={toggleMenu}
          >
            Quíenes Somos
          </NavLink>
          {/* <NavLink
            to="/wallet"
            exact
            activeClassName="selectedLink"
            className="nav__item"
            onClick={toggleMenu}
          >
            personas
          </NavLink> */}
          {currentToken && (
            <>
              <NavLink
                to="/app/operation/new"
                activeClassName="selectedLink"
                className="nav__item"
                onClick={toggleMenu}
              >
                Comprar
              </NavLink>
              <NavLink
                to="/app/profile"
                activeClassName="selectedLink"
                className="nav__item"
                onClick={toggleMenu}
              >
                Perfil
              </NavLink>
            </>
          )}
          {currentToken ? (
            <>
              <button type="button" onClick={logout} className="nav__item">
                Cerrar sesión
              </button>
            </>
          ) : (
            <>
              <a
                rel="noopener noreferrer"
                href={`${feanorapi.webapp}/auth/login`}
                onClick={toggleMenu}
                class="nav__item"
              >
                Iniciar sesión
              </a>
              {/* <a
                rel="noopener noreferrer"
                href={`${feanorapi.webapp}/auth/register`}
                onClick={toggleMenu}
                class="nav__item"
              >
                Registrarse
              </a> */}
            </>
          )}
        </nav>

        <div className="back-drop" onClick={toggleMenu}></div>
      </div>
    );
  } else {
    return (
      <div className="nav-toggle nav-toggle--closed show-mobile">
        <span
          className="nav-toggle__button show-mobile"
          onClick={toggleMenu}
        ></span>

        <nav className="nav__items">
          <NavLink
            to="/"
            exact
            activeClassName="selectedLink"
            className="nav__item"
            onClick={toggleMenu}
          >
            Inicio
          </NavLink>
          <NavLink
            to="/como-empezar"
            exact
            activeClassName="selectedLink"
            className="nav__item"
            onClick={toggleMenu}
          >
            Cómo funciona
          </NavLink>

          <NavLink
            to="/ayuda"
            exact
            activeClassName="selectedLink"
            className="nav__item"
            onClick={toggleMenu}
          >
            Ayuda
          </NavLink>
          {currentToken ? (
            <NavLink
              to="/perfil"
              activeClassName="selectedLink"
              className="nav__item"
              onClick={toggleMenu}
            >
              Perfil
            </NavLink>
          ) : (
            ""
          )}
          {currentToken ? (
            <>
              <button type="button" onClick={logout} className="nav__item">
                Cerrar sesión
              </button>
            </>
          ) : (
            <NavLink
              to="/auth/login"
              className="nav__item"
              onClick={toggleMenu}
            >
              Iniciar sesión
            </NavLink>
          )}
        </nav>
      </div>
    );
  }
};

export default ToggleableNav;

import React, { useEffect } from "react";
import { withRouter } from "react-router";
import Styled from "./styles";
import { PrimaryText, TitleText } from "styles/colorized";
import AllInOne from "pages/Terms/components/AllInOne";
import ReadyForTheFuture from "ReadyForTheFuture";
import { Helmet } from "react-helmet";
import Faqs from "./components/Faqs";

const Faq = () => {
  // Scroll to top on all pages
  const scrollTop = () => {
    window.scrollTo(0, 0);
  };

  useEffect(() => {
    scrollTop();
    // eslint-disable-next-line
  }, []);
  return (
    <>
      <Styled.AboutContainer>
        <Styled.ContainerSectionHeader>
          <Styled.TaglineAbout>
            <TitleText>FAQs</TitleText>
          </Styled.TaglineAbout>
          <Styled.SubtaglineHome>
            <PrimaryText>Preguntas frecuentes</PrimaryText>
          </Styled.SubtaglineHome>
        </Styled.ContainerSectionHeader>
      </Styled.AboutContainer>

      <Styled.ContentBoxedContainer>
        {/* <UseTerms /> */}

        <Faqs />
      </Styled.ContentBoxedContainer>

      <ReadyForTheFuture />

      <Helmet>
        <meta charSet="utf-8" />
        <title> FAQs | FlexBit</title>
        <meta
          name="description"
          content="Términos de Uso de flexbit.io, el primer exchange de bitcoins legal en Panamá."
        />
      </Helmet>
    </>
  );
};

export default withRouter(Faq);

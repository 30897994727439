import React from "react";
import {
  Paragraph,
  Center,
  Subtitle,
  Bold,
  HiperLink,
  ListNumber,
  ItemList,
  ItemText,
  ListNormal,
  Title,
  Table,
  TableRow,
  TableHeader,
  TableCell,
  LeyendTable,
} from "./styles";
import { List } from "styles/Styled";
const PlatformContract = () => {
  return (
    <div style={{ color: "black" }} id="plataforma">
      <Center>TÉRMINOS Y CONDICIONES FLEXBIT</Center>
      <br />
      <Paragraph>
        Los presentes Términos y Condiciones (en adelante, los “TyC”) rigen para
        las operaciones que realicen los usuarios habilitados (en adelante, los
        “Usuarios”) a través de la aplicación FLEXBIT (en adelante, la
        “Plataforma” o la “Aplicación”), cuya titularidad corresponde a{" "}
        <Bold>FLEXBIT S.A.</Bold>, sociedad organizada conforme a las leyes de
        la República de Panamá e inscrita en el Folio N.º 155706843 del Registro
        Público de Panamá.
      </Paragraph>
      {/* aqui OJO CAMBIAR LA DIRECCIÓN URL */}
      <Paragraph>
        Estos TyC se aplican a toda la operatoria que se realice en la
        Plataforma, incluidas las promociones de productos en lo que resulten
        aplicables, y se encuentran disponibles en la página{" "}
        <HiperLink href="https://www.flexbit.app/" target="_blank">
          https://www.flexbit.app/
        </HiperLink>
        , junto con las Políticas de Privacidad y Políticas de Cookies de la
        Compañía. Al hacer clic en “Aceptar” los TyC en la Plataforma, el
        Usuario reconoce que:
      </Paragraph>

      <ListNumber>
        <ItemList>
          <ItemText>Ha leído y entendido los TyC en su totalidad.</ItemText>
        </ItemList>
        <ItemList>
          <ItemText>Acepta su contenido.</ItemText>
        </ItemList>
        <ItemList>
          <ItemText>
            Reconoce que constituyen un acuerdo vinculante entre el Usuario y la
            Empresa.
          </ItemText>
        </ItemList>
      </ListNumber>

      <Paragraph>
        La Empresa se reserva el derecho de revisar estos TyC en cualquier
        momento, actualizando y/o modificándolos. El Usuario deberá leer atenta
        y comprensivamente los presentes TyC de forma regular, ya que pueden
        sufrir modificaciones. Cualquier modificación entrará en vigor desde el
        momento de su publicación en el sitio (“Sitio”) y se presumirá aceptada
        por el Usuario en la medida en que siga utilizando la Plataforma. En
        caso de disconformidad, la Empresa procederá al cierre de la Cuenta del
        Usuario y solicitará instrucciones sobre el destino de los Activos
        registrados en la misma; hasta tanto no se reciba dicha instrucción, la
        Cuenta permanecerá bloqueada.
      </Paragraph>

      <Paragraph>
        Si el Usuario <Bold>no comprende</Bold> o no está de acuerdo con estos
        TyC, deberá abstenerse de interactuar o acceder a la Plataforma.
      </Paragraph>

      <Title>1. Definiciones</Title>
      <Paragraph>
        Las siguientes palabras y expresiones utilizadas en estos TyC tendrán
        los significados que se detallan a continuación:
      </Paragraph>

      <ListNormal>
        <ItemList>
          <ItemText>
            <Bold>“Activo Digital” o “Criptomoneda”</Bold>: Significa el activo
            digital Bitcoin o cualquier otro criptoactivo que la Plataforma
            ofrezca en el futuro.
          </ItemText>
        </ItemList>
        <ItemList>
          <ItemText>
            <Bold>“Billetera” (Wallet)</Bold>: Significa una aplicación de
            software u otro mecanismo cuya finalidad es la custodia y
            transferencia de Activos Digitales.
          </ItemText>
        </ItemList>
        <ItemList>
          <ItemText>
            <Bold>“Cuenta”</Bold>: Registro en la Plataforma mediante el cual se
            identifica a cada Usuario y sus operaciones.
          </ItemText>
        </ItemList>
        <ItemList>
          <ItemText>
            <Bold>“Divisas Fiduciarias” o “FIAT”</Bold>: Moneda de curso legal
            para la jurisdicción del Usuario.
          </ItemText>
        </ItemList>
        <ItemList>
          <ItemText>
            <Bold>“KRAKEN”</Bold>: Nombre comercial de Payward Brokers PTE. Ltd.
            (compañía constituida en Singapur, n.º de registro 201917834G,
            dirección registrada en 8 Temasek Boulevard, #15-04, Suntec Tower
            Three, Singapur 038988) y Payward Global Trading Pte. Ltd. (compañía
            constituida en Singapur, n.º de registro 201913551K, dirección
            registrada en 8 Temasek Boulevard, #15-04, Suntec Tower Three,
            Singapur 038988).
          </ItemText>
        </ItemList>

        <ItemList>
          <ItemText>
            <Bold>“Servicios”</Bold>: Servicios ofrecidos a los Usuarios por la
            Plataforma, que incluyen la compra, venta, canje y custodia de
            Bitcoin y otros Activos Digitales que en el futuro se habiliten en
            la interfaz de Usuario a través de servicios brindados por FLEXBIT
            S.A.
          </ItemText>
        </ItemList>
        <ItemList>
          <ItemText>
            <Bold>“Usuario”</Bold>: Persona física, mayor de edad de acuerdo con
            la legislación vigente de su jurisdicción, que acepta los TyC con el
            fin de ser titular de una Cuenta en la Plataforma y utilizar los
            Servicios ofrecidos.
          </ItemText>
        </ItemList>
        <ItemList>
          <ItemText>
            <Bold>“Airdrop”</Bold>: Procedimiento de distribución de nuevos
            tokens otorgados a poseedores de Activos Digitales.
          </ItemText>
        </ItemList>
      </ListNormal>

      <Title>2. Alta de Cuenta en la Plataforma</Title>
      <Paragraph>
        Para la apertura de la Cuenta en la Plataforma, el Usuario deberá
        proporcionar toda la información solicitada de forma veraz, precisa y
        completa. Cada Usuario podrá ser titular de <Bold>una única</Bold>{" "}
        Cuenta en la Plataforma. La Plataforma se reserva el derecho de aceptar
        o no a cualquier potencial Usuario, basándose en la evaluación de los
        documentos proporcionados al momento de la solicitud de apertura de la
        Cuenta.
      </Paragraph>
      <Subtitle>2.1. Jurisdicciones con restricción internacional</Subtitle>
      <Paragraph>
        Existen países en los cuales, por restricciones internacionales o
        decisiones comerciales, la Plataforma no se encuentra disponible. En
        relación con las decisiones comerciales, la Empresa decidirá en qué
        jurisdicciones podrá proporcionar los Servicios.
      </Paragraph>
      <Paragraph>
        De acuerdo con las políticas de la Empresa, se consideran restringidas
        las siguientes jurisdicciones: Balcanes, Bielorrusia, Birmania, Burundi,
        República Centroafricana, Corea del Norte, Costa de Marfil, Crimea,
        Cuba, República Democrática del Congo, Estados Unidos, Hong Kong, Irán,
        Irak, Líbano, Libia, Liberia, Mali, Palestina, Rusia, Somalia, Sudán y
        Darfur, Sudán del Sur, Siria, Ucrania, Yemen y Zimbabue.
      </Paragraph>
      <Paragraph>
        Estas jurisdicciones se consideran de alto riesgo, con medidas débiles
        para la prevención del lavado de dinero y financiamiento del terrorismo
        (PLA/FT), o con deficiencias estratégicas en esa materia. La Empresa se
        reserva el derecho de rechazar nuevas solicitudes de registro a su
        entera discreción y/o en virtud de la normativa aplicable.
      </Paragraph>

      <Title>3. Utilización de los Servicios en la Plataforma</Title>
      <Subtitle>3.1. Alcance general</Subtitle>
      <Paragraph>
        La Plataforma está destinada a ofrecer servicios de compra y venta
        digital de criptoactivos, cuyos precios están establecidos en FIAT,
        dependiendo del país desde el que se opere. Los criptoactivos
        susceptibles de ser comprados o vendidos en la Plataforma se encuentran
        <Bold>taxativamente</Bold> enunciados en la misma. El Usuario entiende y
        acepta que la Empresa se limita a ofrecer el servicio de intermediación
        para compra y venta de criptoactivos, sin injerencia respecto al valor,
        volatilidad o características de dichos activos.
      </Paragraph>
      <Paragraph>
        La Empresa podrá, bajo su exclusiva voluntad, agregar nuevos
        criptoactivos o dejar de soportar uno o más de los que actualmente
        ofrece, sin que ello implique obligación alguna de mantener
        permanentemente dicho soporte.
      </Paragraph>
      <Subtitle>3.2. Instrucciones</Subtitle>
      <Paragraph>
        Los Usuarios emitirán instrucciones de compra, venta, canje y permuta de
        Activos Digitales a través de la Plataforma (en adelante, la
        “Instrucción”). Al emitir una Instrucción, el Usuario reconoce:
      </Paragraph>

      <ListNumber>
        <ItemList>
          <ItemText>
            Que una vez ejecutada, la transacción es <Bold>irreversible</Bold>
          </ItemText>
        </ItemList>
        <ItemList>
          <ItemText>
            Que no se puede cancelar una transacción ya ejecutada.
          </ItemText>
        </ItemList>
        <ItemList>
          <ItemText>
            Que la Empresa puede establecer montos mínimos y/o máximos por
            transacción, informados en la Plataforma.
          </ItemText>
        </ItemList>
      </ListNumber>

      <Paragraph>
        El Usuario es <Bold>el único responsable</Bold> de determinar si cada
        transacción se ajusta a su situación financiera y tolerancia al riesgo.
        Todas las Instrucciones se emiten <Bold>a propio riesgo</Bold> del
        Usuario.
      </Paragraph>

      <Subtitle>3.3. Cancelación de Instrucciones</Subtitle>
      <Paragraph>
        El Usuario acepta que la Empresa no puede garantizar la cancelación o
        modificación de una Instrucción antes de su ejecución, salvo aprobación
        expresa de la Empresa. Asimismo, la Empresa:
      </Paragraph>

      <ListNormal>
        <ItemList>
          <ItemText>
            Realizará sus mejores esfuerzos para ejecutar todas las
            Instrucciones, pero <Bold>no garantiza</Bold> la ejecución de ningún
            tipo de operación en un precio o monto específico.
          </ItemText>
        </ItemList>
        <ItemList>
          <ItemText>
            Se reserva el derecho de no ejecutar Instrucciones que violen estos
            TyC, las leyes, las regulaciones, las normas o las políticas
            internas de la Empresa, o que impliquen la comisión de cualquier
            acto ilícito (lavado de activos, financiamiento del terrorismo,
            fraude, etc.).
          </ItemText>
        </ItemList>
        <ItemList>
          <ItemText>
            Podrá abstenerse de ejecutar Instrucciones por fuerza mayor, fallas
            técnicas, cierre prematuro de operaciones, disposiciones de una
            autoridad reguladora o gubernamental, entre otros supuestos.
          </ItemText>
        </ItemList>
      </ListNormal>

      <Subtitle>Retiros en FIAT</Subtitle>
      <ListNormal>
        <ItemList>
          <ItemText>
            El <Bold>retiro mínimo</Bold> es de 25 dólares estadounidenses (USD
            25).
          </ItemText>
        </ItemList>
        <ItemList>
          <ItemText>
            El Usuario declara bajo juramento ser el titular de la cuenta
            bancaria de destino para retiros (ACH o ACH Xpress).
          </ItemText>
        </ItemList>
        <ItemList>
          <ItemText>
            Si se demuestra posteriormente que la declaración es falsa, el
            Usuario asume todos los costos y gastos que se generen, liberando a
            FLEXBIT S.A., sus accionistas, directores, dignatarios, ejecutivos,
            empleados, agentes y representantes de cualquier reclamo.
          </ItemText>
        </ItemList>
      </ListNormal>

      <Paragraph>
        Si el Usuario realiza un <Bold>depósito</Bold> por ACH o ACH Xpress
        desde una cuenta bancaria que <Bold>no</Bold> está a su nombre, será
        rechazado y devuelto a la cuenta de origen en un plazo que podría ser
        mayor a 30 días, sin posibilidad de acreditarse ni de interponer
        reclamos.
      </Paragraph>

      <Subtitle>3.5. Seguridad de la Cuenta</Subtitle>
      <Paragraph>
        El Usuario accederá a su Cuenta con su correo electrónico y{" "}
        <Bold>contraseña personal</Bold> (“Clave de Ingreso”), comprometiéndose
        a mantener la confidencialidad de todos sus métodos de acceso
        (contraseñas, PIN, códigos de desbloqueo del dispositivo, etc.). El
        Usuario es <Bold>exclusivamente responsable</Bold> por todas las
        operaciones realizadas en su Cuenta.
      </Paragraph>
      <Paragraph>
        La Empresa <Bold>nunca</Bold> pedirá al Usuario que divulgue sus
        contraseñas. En caso de uso no autorizado de la Cuenta, el Usuario
        deberá notificar de inmediato a la Empresa por los canales habilitados.
      </Paragraph>

      <Subtitle>3.6. MFA (Multi Factor Authentication)</Subtitle>
      <Paragraph>
        La Plataforma permite al Usuario habilitar MFA para mayor seguridad. Al
        realizar transacciones o agregar cuentas de destino, se solicitará un
        código enviado por SMS al teléfono registrado. El Usuario debe informar
        a la Empresa cualquier cambio en sus datos (incluido su teléfono) dentro
        de los cinco (5) días hábiles posteriores al cambio.
      </Paragraph>

      <Subtitle>3.7. Bloqueo por depósitos en efectivo</Subtitle>
      <Paragraph>
        Los depósitos en efectivo, tanto de usuarios registrados como de no
        usuarios, en cuentas a nombre de FLEXBIT S.A. están terminantemente
        prohibidos. De realizarse, serán rechazados y podrán constituir causal
        de cierre de la Cuenta.
      </Paragraph>

      <Title>Costos</Title>
      <Subtitle>4.1. Comisiones y tarifas</Subtitle>
      <Paragraph>
        Los Servicios ofrecidos incluyen la comisión de la Plataforma en el
        precio final que se muestra al Usuario. <Bold>No</Bold> se cobra ninguna
        comisión adicional. El precio que aparece en la Plataforma al momento de
        confirmar la operación ya incluye la tarifa de la Plataforma.
      </Paragraph>

      <Table>
        {/* <thead>
          <TableRow>
            <TableHeader>FEE TRANSACCIONES</TableHeader>
            <TableHeader>USDT/CNKT</TableHeader>
            <TableHeader>3%</TableHeader>
          </TableRow>
        </thead> */}
        <tbody>
          <TableRow>
            <TableCell>FEE TRANSACCIONES</TableCell>
            <TableCell>USDT/CNKT</TableCell>
            <TableCell>3%</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>FEE TRANSACCIONES</TableCell>
            <TableCell>BTC/CNKT</TableCell>
            <TableCell>3%</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>COMPRA/FIAT</TableCell>
            <TableCell>FIAT/CNKT</TableCell>
            <TableCell>5%</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>FLEXCARD</TableCell>
            <TableCell>CNKT/TARJETA</TableCell>
            <TableCell>5%</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>P2P</TableCell>
            <TableCell>CNKT/CNKT</TableCell>
            <TableCell>0,5%</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>FLEXCASH</TableCell>
            <TableCell>CNKT/FIAT</TableCell>
            <TableCell>1%</TableCell>
          </TableRow>
        </tbody>
      </Table>

      <LeyendTable>
        Estos fee pueden varias de acuerdo a las condiciones de mercados o
        regulatorias.
      </LeyendTable>

      <Subtitle>4.2. Truncamiento de decimales</Subtitle>
      <Paragraph>
        Para el cálculo de comisiones, el resultado se trunca a dos cifras
        decimales.
      </Paragraph>

      <Title>5. Instrucciones de compra/venta y retiros</Title>
      <Paragraph>
        Para acceder a los Servicios de la Plataforma, el Usuario debe contar
        con saldo suficiente en su Cuenta (ya sea en Divisas Fiduciarias o en
        Activos Digitales, según la operación).
      </Paragraph>

      <Paragraph>
        El <Bold>precio</Bold> de los criptoactivos mostrado en la sección
        “Mercado” puede variar entre el momento de acceso y la confirmación de
        la operación, sin que la Empresa sea responsable por dichas
        fluctuaciones. El Usuario reconoce que la Empresa <Bold>no fija</Bold>{" "}
        el valor de los criptoactivos y que el precio puede depender de factores
        de mercado, oferta, demanda u otros aspectos exógenos.
      </Paragraph>

      <Paragraph>
        La Plataforma <Bold>garantiza</Bold> que los precios mostrados para
        transaccionar <Bold>ya incluyen</Bold> la tarifa transaccional o fee de
        la Plataforma, siendo ese el <Bold>monto final </Bold>a abonar.
      </Paragraph>
      <Subtitle>5.1. Instrucciones de Retiros</Subtitle>
      <ListNormal>
        <ItemList>
          <ItemText>
            Solo se podrán realizar retiros en la moneda de curso legal del país
            (FIAT) o en los Activos Digitales disponibles en la Cuenta.
          </ItemText>
        </ItemList>
        <ItemList>
          <ItemText>
            En caso de retiro FIAT, solo puede ejecutarse a una cuenta bancaria
            o institución financiera a nombre del Usuario.
          </ItemText>
        </ItemList>
        <ItemList>
          <ItemText>
            En el caso de retiro de Activos Digitales, se enviarán a la Wallet
            especificada por el Usuario. Algunas Wallets requieren datos
            adicionales que el Usuario debe suministrar.
          </ItemText>
        </ItemList>
        <ItemList>
          <ItemText>
            La Empresa <Bold>no puede</Bold> cancelar una Instrucción de Retiro
            una vez iniciada.
          </ItemText>
        </ItemList>
        <ItemList>
          <ItemText>
            La Empresa <Bold>no garantiza</Bold> plazos de efectivización de los
            retiros, los cuales pueden demorarse por causas ajenas a la Empresa
            (bancos, redes blockchains, etc.).
          </ItemText>
        </ItemList>
        <ItemList>
          <ItemText>
            Si la cuenta bancaria informada es incorrecta o está inhabilitada,
            el retiro no podrá ejecutarse hasta subsanar el error.
          </ItemText>
        </ItemList>
      </ListNormal>

      <Subtitle>5.2. Transferencias entre Usuarios</Subtitle>
      <Paragraph>
        Por regla general, los Usuarios <Bold>solo</Bold> pueden transmitir
        Activos Digitales entre sí, estando prohibido transferir Divisas
        Fiduciarias u otras transacciones no previstas en estos TyC.
      </Paragraph>

      <Subtitle>5.3. Conversiones</Subtitle>
      <Paragraph>
        La Empresa proporciona servicios de conversión entre Activos Digitales y
        Divisas Fiduciarias, pudiendo integrar otras redes o proveedores
        externos para ejecutar las Instrucciones. No se garantiza la estabilidad
        ni la invariabilidad de precios en las conversiones.
      </Paragraph>

      <Subtitle>5.4. Límites</Subtitle>
      <Paragraph>
        La Empresa puede establecer límites mensuales y/o anuales de operación
        para cumplir con los estándares de prevención de lavado de dinero y
        financiamiento al terrorismo (PLA/FT).
      </Paragraph>
      <Title>6. Servicios provistos por proveedores externos y KRAKEN</Title>
      <Subtitle>6.1. Proveedores de liquidez y medios de pago</Subtitle>
      <Paragraph>
        La Empresa se asocia con terceros para ofrecer servicios de conversión
        de FIAT a criptomonedas y viceversa. Estos proveedores pueden variar con
        el tiempo y están sujetos a sus propios términos y condiciones.
      </Paragraph>
      <Subtitle>6.2. Acuerdo con KRAKEN</Subtitle>
      <Paragraph>
        La Empresa suscribe un acuerdo con KRAKEN para que el Usuario compre e
        intercambie criptomonedas en la Plataforma utilizando los servicios de
        KRAKEN. En ciertos casos, KRAKEN actuará como custodio externo de las
        criptomonedas del Usuario.
      </Paragraph>
      <Subtitle>6.3. Aceptación de Términos de KRAKEN</Subtitle>
      <Paragraph>
        El Usuario declara que comprende y acepta los Términos y Condiciones y
        Políticas de Privacidad de KRAKEN (disponibles en{" "}
        <HiperLink href="https://www.kraken.com/" target="_blank">
          https://www.kraken.com/
        </HiperLink>
        ), por lo cual se convierte en cliente de KRAKEN a tales efectos y
        mantiene una relación contractual directa con dicha entidad.
      </Paragraph>
      <Subtitle>6.4. Interacción</Subtitle>
      <Paragraph>
        Toda transacción de compra o venta de criptomonedas se inicia desde la
        Plataforma, y el Usuario debe autenticarse con sus credenciales. La
        interacción directa con KRAKEN será excepcional (investigaciones de
        fraude, resolución de quejas, etc.).
      </Paragraph>

      <Title>7. Riesgos asociados a los Activos Digitales</Title>
      <Paragraph>
        El Usuario comprende que las inversiones en Activos Digitales pueden
        implicar mayores riesgos que las inversiones tradicionales. La Empresa
        <Bold>no brinda asesoramiento financiero</Bold> ni asume responsabilidad
        por pérdidas ocasionadas por la volatilidad de los criptoactivos.
      </Paragraph>
      <Title>8. Correcto uso de la Cuenta</Title>
      <ListNumber>
        <ItemList>
          <ItemText>
            El Usuario no utilizará los Servicios con fines ilegales, ni
            realizará operaciones que contravengan normas locales o
            internacionales, ni que impliquen lavado de activos, financiamiento
            del terrorismo u otras actividades ilícitas.
          </ItemText>
        </ItemList>
        <ItemList>
          <ItemText>
            La Cuenta es personal, única e intransferible;{" "}
            <Bold>está prohibida</Bold> su venta, cesión o transferencia a
            terceros.
          </ItemText>
        </ItemList>
        <ItemList>
          <ItemText>
            La Empresa se reserva el derecho de bloquear o cerrar la Cuenta en
            caso de detectar un uso indebido o sospechas de fraude.
          </ItemText>
        </ItemList>
        <ItemList>
          <ItemText>
            En caso de ataques, interrupciones o fallas, la Empresa podrá
            revisar las transacciones realizadas y realizar ajustes necesarios
            para subsanar errores de mercado o precios fuera de rango.
          </ItemText>
        </ItemList>
        <ItemList>
          <ItemText>
            Queda prohibida cualquier práctica de “arbitraje abusivo”, “sniping”
            o “scalping” que busque explotar errores de latencia de precios; la
            Empresa podrá invalidar esas transacciones y suspender la Cuenta del
            Usuario.
          </ItemText>
        </ItemList>
      </ListNumber>
      <Title>9. Suspensión o Terminación</Title>
      <Paragraph>
        La Empresa podrá cancelar o suspender la Cuenta del Usuario si:
      </Paragraph>
      <ListNormal>
        <ItemList>
          <ItemText>Se denuncia una violación de seguridad.</ItemText>
        </ItemList>
        <ItemList>
          <ItemText>
            Hay requerimiento judicial o de autoridad competente.
          </ItemText>
        </ItemList>
        <ItemList>
          <ItemText>Hay sospecha de fraude o uso ilegal.</ItemText>
        </ItemList>
        <ItemList>
          <ItemText>
            Falta documentación para la apertura o mantenimiento de la Cuenta.
          </ItemText>
        </ItemList>
        <ItemList>
          <ItemText>
            Se incumplen estos TyC o la Política de Privacidad.
          </ItemText>
        </ItemList>
      </ListNormal>
      <Paragraph>
        Ante suspensión o cancelación, se notificará al Usuario por correo
        electrónico. El Usuario no podrá emitir nuevas Instrucciones (salvo
        retirar sus tenencias, si no hay orden judicial o administrativa de
        retención).
      </Paragraph>
      <Title>10. Cierre de Cuenta a solicitud del Usuario</Title>
      <Paragraph>
        El Usuario puede solicitar el cierre de su Cuenta a través de la
        Aplicación o canales habilitados, lo cual se procesará en un plazo de
        hasta 30 días, siempre que no posea saldo pendiente. En caso de
        retención dispuesta por autoridad competente, no se podrá proceder al
        cierre hasta que la restricción cese.
      </Paragraph>
      <Title>11. Propiedad Intelectual</Title>
      <Paragraph>
        Los contenidos de la Plataforma, programas, bases de datos, redes y
        archivos que permiten el acceso y uso de la Cuenta son de propiedad
        exclusiva de la Empresa y están protegidos por leyes y tratados
        internacionales de propiedad intelectual. El uso indebido y la
        reproducción total o parcial de dichos contenidos queda prohibido, salvo
        autorización escrita de la Empresa.
      </Paragraph>
      <Title>12. Limitación de Responsabilidad</Title>
      <ListNumber>
        <ItemList>
          <ItemText>
            La Empresa actúa únicamente como <Bold>intermediario</Bold> para que
            el Usuario compre y venda criptomonedas. <Bold>No ofrece </Bold>{" "}
            servicios de custodia, almacenamiento o protección de criptomonedas;
            el Usuario es responsable de proteger sus claves privadas.
          </ItemText>
        </ItemList>
        <ItemList>
          <ItemText>
            La Empresa no es responsable por decisiones arbitrarias de los
            bancos que congelen, suspendan o cierren cuentas, tanto de la
            Empresa como del Usuario.
          </ItemText>
        </ItemList>
        <ItemList>
          <ItemText>
            La Empresa no será responsable por daños o interrupciones del
            servicio ocasionados por causas externas, virus informáticos,
            spyware u otro malware, ni por ataques de tipo phishing o similares.
          </ItemText>
        </ItemList>
        <ItemList>
          <ItemText>
            La Empresa no será responsable por cualquier incumplimiento o demora
            derivados de fuerza mayor, guerra, terrorismo, desastres naturales,
            disposiciones gubernamentales o cualquier situación fuera de su
            control.
          </ItemText>
        </ItemList>
        <ItemList>
          <ItemText>
            El acceso a los Servicios puede verse limitado en períodos de alta
            demanda o volatilidad. La Empresa no se hace responsable de
            interrupciones, demoras en la ejecución de transacciones ni de la
            estabilidad de la Plataforma en tales circunstancias.
          </ItemText>
        </ItemList>
        <ItemList>
          <ItemText>
            El Usuario es responsable de <Bold>todas</Bold> las obligaciones
            fiscales derivadas de sus operaciones.
          </ItemText>
        </ItemList>
        <ItemList>
          <ItemText>
            El Usuario asume la responsabilidad en caso de enviar Activos
            Digitales a direcciones incorrectas o redes incompatibles, perdiendo
            así los fondos de forma irreparable.
          </ItemText>
        </ItemList>
        <ItemList>
          <ItemText>
            La Empresa no se responsabiliza si el Usuario envía activos no
            listados en la Plataforma y, en caso de solicitar conversión o
            devolución, deberá contactar a Soporte dentro de los 30 días e
            incluir todos los datos de la transferencia.
          </ItemText>
        </ItemList>
      </ListNumber>
      <Title>13. Ausencia de asesoría financiera o legal</Title>
      <Paragraph>
        La Empresa <Bold>no</Bold> brinda asesoría financiera, de inversión o
        legal. La información de precios, volatilidad u otros eventos de mercado
        es meramente informativa. Cualquier decisión de comprar o vender Activos
        Digitales es exclusiva del Usuario y la Empresa no será responsable por
        pérdidas sufridas.
      </Paragraph>
      <Title>14. Enlaces Externos</Title>
      <Paragraph>
        La Plataforma puede contener enlaces a sitios o aplicaciones de
        terceros. La Empresa no tiene control sobre dichos sitios ni es
        responsable por sus contenidos o servicios. La presencia de enlaces no
        implica relación, aprobación ni respaldo alguno.
      </Paragraph>
      <Title>15. Fallecimiento del titular de la Cuenta</Title>
      <Paragraph>
        En caso de fallecimiento o incapacidad del Usuario, los herederos o
        representantes legales deberán presentar la documentación exigida por la
        Empresa. Hasta tanto no se cumplan esos requisitos, la Cuenta
        permanecerá suspendida. Tras la verificación, se autorizará el retiro de
        los fondos y el cierre de la Cuenta.
      </Paragraph>
      <Title>16. Fork</Title>
      <Paragraph>
        Los protocolos (los “Protocolos”) que sostienen la operación de cada
        Activo Digital <Bold>no</Bold> son controlados por la Plataforma. Estos
        Protocolos pueden sufrir <Bold>Forks</Bold> que afecten el valor, la
        utilidad o la disponibilidad del Activo Digital. En tal caso:
      </Paragraph>
      <ListNormal>
        <ItemList>
          <ItemText>
            La Plataforma puede suspender temporalmente la operatoria (con o sin
            aviso previo).
          </ItemText>
        </ItemList>
        <ItemList>
          <ItemText>
            Podrá decidir, a su sola discreción, soportar o no el nuevo activo
            digital resultante.
          </ItemText>
        </ItemList>
        <ItemList>
          <ItemText>
            No tendrá responsabilidad alguna por perjuicios derivados de Forks
            no soportados.
          </ItemText>
        </ItemList>
      </ListNormal>
      <Paragraph>
        Si el Usuario desea participar del resultado de un Fork no soportado,
        deberá retirar los activos de su Cuenta antes de la fecha anunciada para
        el Fork.
      </Paragraph>
      <Title>17. Contacto con el personal de la Empresa</Title>
      <Paragraph>
        Cualquier interacción que el Usuario mantenga con empleados de la
        Empresa será para brindar soporte. Está prohibido divulgar la
        comunicación con el personal, así como sus datos personales (nombre,
        teléfono, correo, etc.). El incumplimiento de este punto podrá acarrear
        el cierre de la Cuenta y/o acciones legales.
      </Paragraph>
      <Title>18. Divisibilidad</Title>
      <Paragraph>
        Si alguna disposición de estos TyC se considera ilegal, nula o
        inaplicable, se considerará separada de las restantes, las cuales
        conservarán su validez y aplicabilidad.
      </Paragraph>
      <Title>19. Cesión</Title>
      <ListNumber>
        <ItemList>
          <ItemText>
            Estos TyC, la relación jurídica y los derechos u obligaciones
            resultantes no pueden ser cedidos ni transferidos por el Usuario a
            terceros.
          </ItemText>
        </ItemList>
        <ItemList>
          <ItemText>
            En caso de adquisición, fusión, escisión o transformación de la
            Empresa, esta se reserva el derecho de transferir o ceder los TyC y
            la información del Usuario, conforme la normativa aplicable de
            protección de datos personales.
          </ItemText>
        </ItemList>
      </ListNumber>

      <Title>20. Canales de atención y comunicación</Title>
      <Paragraph>
        Las notificaciones que la Empresa deba realizar al Usuario se
        considerarán válidas cuando se realicen a través de la Plataforma o a la
        dirección de correo electrónico registrada por el Usuario. Para
        consultas, quejas o reclamos, el Usuario puede comunicarse por los
        canales disponibles en{" "}
        <HiperLink href="https://www.flexbit.app/" target="_blank">
          https://www.flexbit.app/
        </HiperLink>
        .
      </Paragraph>
      <Title>21. Canales de información al Usuario</Title>
      <Paragraph>
        La Empresa dispone de distintos canales informativos detallados en la
        web{" "}
        <HiperLink href="https://www.flexbit.app/" target="_blank">
          https://www.flexbit.app/
        </HiperLink>
        , tales como redes sociales (Instagram, Facebook, Twitter) y un correo
        de ayuda (
        <HiperLink href="mailto:clientes@flexbit.app">
          clientes@flexbit.app
        </HiperLink>
        ).
      </Paragraph>
      <Title>22. Jurisdicción y Ley Aplicable</Title>
      <Paragraph>
        La interpretación y alcance de estos TyC se regirán por la normativa
        vigente de la República de Panamá
      </Paragraph>

      <Title>23. Disclaimers</Title>
      <Paragraph>
        Además de todo lo establecido, y con el propósito de{" "}
        <Bold>disminuir aún más los riesgos</Bold>, se incorporan los siguientes
        20 puntos de disclaimer y aclaraciones complementarias:
      </Paragraph>
      <ListNumber>
        <ItemList>
          <ItemText>
            <Bold>No somos asesores de inversión:</Bold> Toda información sobre
            precios o tendencias de mercado es meramente referencial. Las
            decisiones de inversión corresponden exclusivamente al Usuario,
            quien asume los riesgos asociados.
          </ItemText>
        </ItemList>
        <ItemList>
          <ItemText>
            <Bold>Volatilidad extrema:</Bold> Los criptoactivos pueden
            experimentar fluctuaciones bruscas de precio. El Usuario reconoce
            que dichas variaciones pueden generar pérdidas significativas en
            períodos muy cortos.
          </ItemText>
        </ItemList>
        <ItemList>
          <ItemText>
            <Bold>Créditos y tarjetas de terceros: </Bold> La Empresa no
            controla ni garantiza la disponibilidad, funcionalidad o seguridad
            de las tarjetas de crédito o líneas de crédito emitidas por
            terceros. El Usuario es el único responsable de revisar las
            condiciones de su emisor de tarjeta o entidad financiera.
          </ItemText>
        </ItemList>
        <ItemList>
          <ItemText>
            <Bold>Posibles contracargos o bloqueos:</Bold> En transacciones con
            tarjetas de crédito, pueden surgir contracargos, devoluciones o
            bloqueos a criterio de la entidad emisora o procesadora, sin
            intervención ni responsabilidad de la Empresa.
          </ItemText>
        </ItemList>
        <ItemList>
          <ItemText>
            <Bold>Cumplimiento de regulaciones locales:</Bold> El Usuario
            declara que el uso de la Plataforma no viola leyes o regulaciones de
            su jurisdicción y que cumplirá con toda normativa fiscal, cambiaria
            o de prevención de delitos económicos.
          </ItemText>
        </ItemList>
        <ItemList>
          <ItemText>
            <Bold>Cambios regulatorios:</Bold> La normativa sobre criptoactivos
            y servicios financieros evoluciona constantemente. La Empresa no es
            responsable de eventuales restricciones legales o regulatorias que
            puedan afectar al Usuario.
          </ItemText>
        </ItemList>
        <ItemList>
          <ItemText>
            <Bold>Responsabilidad del Usuario sobre credenciales:</Bold> El
            Usuario debe salvaguardar sus credenciales de acceso y contraseñas.
            Cualquier vulneración de seguridad resultante de descuidos recae
            sobre el Usuario.
          </ItemText>
        </ItemList>
        <ItemList>
          <ItemText>
            <Bold>Ciberseguridad y hacking: </Bold> Pese a los esfuerzos de la
            Empresa por mantener la seguridad, existe riesgo de ataques
            informáticos. El Usuario asume los riesgos y, en caso de duda,
            deberá proteger sus fondos utilizando billeteras externas seguras.
          </ItemText>
        </ItemList>
        <ItemList>
          <ItemText>
            <Bold>Fallas en redes blockchain:</Bold> Las transacciones de
            criptoactivos dependen de redes descentralizadas. Retrasos, costos
            variables de gas (comisiones en cadena) y fallas en dichas redes
            escapan al control de la Empresa.
          </ItemText>
        </ItemList>
        <ItemList>
          <ItemText>
            <Bold>Respaldo de información:</Bold> Se recomienda al Usuario
            llevar registros y respaldos de sus movimientos y saldos, en caso de
            que sea necesaria la verificación de alguna transacción.
          </ItemText>
        </ItemList>
        <ItemList>
          <ItemText>
            <Bold>Impuestos y obligaciones:</Bold> El Usuario es responsable de
            reportar sus transacciones a las autoridades fiscales y de cumplir
            con todas las obligaciones tributarias que correspondan.
          </ItemText>
        </ItemList>
        <ItemList>
          <ItemText>
            <Bold>Sin garantía de rentabilidad:</Bold> La Empresa no garantiza
            retornos ni beneficios económicos. El Usuario entiende que el valor
            de los criptoactivos puede incrementarse o disminuir y no existe
            rentabilidad asegurada.
          </ItemText>
        </ItemList>
        <ItemList>
          <ItemText>
            <Bold>Limitaciones de servicio: </Bold> La Empresa podrá, en
            cualquier momento, limitar, suspender o interrumpir temporal o
            definitivamente el acceso a la Plataforma o a ciertos servicios, sin
            que ello genere derecho a indemnización.
          </ItemText>
        </ItemList>
        <ItemList>
          <ItemText>
            <Bold>Uso restringido a mayores de edad: </Bold> El servicio está
            dirigido únicamente a personas con la capacidad legal para contratar
            en su jurisdicción. El Usuario manifiesta ser mayor de edad y
            cumplir con la legislación aplicable.
          </ItemText>
        </ItemList>
        <ItemList>
          <ItemText>
            <Bold>Integridad de datos: </Bold> La Empresa realiza esfuerzos
            razonables para garantizar la precisión de la información en la
            Plataforma. Sin embargo, no se hace responsable por errores,
            omisiones o inexactitudes que puedan presentarse.
          </ItemText>
        </ItemList>
        <ItemList>
          <ItemText>
            <Bold>Propiedad intelectual de terceros: </Bold> Referencias a
            nombres, marcas o logotipos de terceros (ej., KRAKEN) son únicamente
            para identificación de sus servicios. No se otorga licencia ni se
            asume responsabilidad por contenidos o productos de terceros.
          </ItemText>
        </ItemList>
        <ItemList>
          <ItemText>
            <Bold>Suspensión de cuenta por actividad sospechosa: </Bold>Si se
            detectan operaciones inusuales o indicios de fraude, la Empresa
            podrá suspender la Cuenta sin previo aviso y requerir documentación
            adicional al Usuario.
          </ItemText>
        </ItemList>
        <ItemList>
          <ItemText>
            <Bold>No intermediamos en disputas: </Bold>Cualquier controversia
            entre Usuarios o entre un Usuario y un tercero (bancos, emisores de
            tarjetas, etc.) no constituye responsabilidad de la Empresa, que se
            limita a administrar la Plataforma.
          </ItemText>
        </ItemList>
        <ItemList>
          <ItemText>
            <Bold>Recomendación de asesoría profesional: </Bold>Se exhorta al
            Usuario a consultar con profesionales (abogados, contadores,
            asesores financieros) antes de tomar decisiones de inversión, dada
            la complejidad y volatilidad del mercado cripto.
          </ItemText>
        </ItemList>
        <ItemList>
          <ItemText>
            <Bold>Aceptación expresa: </Bold>Al utilizar la Plataforma y sus
            Servicios, el Usuario declara haber leído, comprendido y aceptado
            todos los términos, condiciones y disclaimers aquí establecidos, así
            como las Políticas de la Empresa.
          </ItemText>
        </ItemList>
      </ListNumber>
      {/* <Paragraph>
        c) Prohibición de sublicencia o distribución: <Bold>EL CLIENTE</Bold>{" "}
        utilizará el Sistema y los Servicios sólo para el ejercicio de sus
        propias actividades y no sublicenciará, distribuirá u ofrecerá el
        Sistema, los Servicios o datos y/o la información comunicada a través
        del Sistema a ninguna persona excepto las Personas Autorizadas.
      </Paragraph>



      <Paragraph>
        a) Código de Acceso y Contraseña: El uso de <Bold>LA PLATAFORMA</Bold>,
        por parte de
        <Bold>EL CLIENTE</Bold>, requerirá del uso combinado de un número de
        identificación del usuario (User ID) y un número de clave de
        identificación personal (Password), otorgados a <Bold>EL CLIENTE</Bold>{" "}
        por <Bold>FLEXBIT</Bold>. Queda expresamente convenido que será
        responsabilidad exclusiva de <Bold>EL CLIENTE</Bold>, el buen uso y
        conservación del User ID y el Password, especialmente la
        confidencialidad de estos, para evitar usos no autorizados. Será de
        exclusiva responsabilidad de <Bold>EL CLIENTE</Bold> el compartir con
        otra u otras personas su User ID y su Password, para el acceso, de tales
        personas, a los servicios objeto del presente contrato. No obstante,{" "}
        <Bold>EL CLIENTE</Bold>
        podrá autorizar a terceros el uso de <Bold>LA PLATAFORMA</Bold> para el
        manejo de su(s) cuenta(s). En estos casos <Bold>EL CLIENTE</Bold> deberá
        reportar a <Bold>FLEXBIT</Bold> el nombre, apellido y demás datos de
        identificación personal a efectos de que <Bold>FLEXBIT</Bold> le
        suministre un número diferente de User ID y de Password, de manera tal
        que se le pueda identificar individualmente en el uso de los servicios
        ofrecidos en este contrato. Queda expresamente convenido que en estos
        casos en que EL CLIENTE autorice a terceros el uso de los servicios
        objeto del presente contrato, serán de su exclusiva responsabilidad y
        del tercero autorizado, los daños y/o perjuicios que el mal uso del
        sistema pueda ocasionarle a <Bold>EL CLIENTE</Bold> o a terceros.
      </Paragraph>
      <Paragraph>
        Toda comunicación electrónica que cumpla con los requisitos de
        identificación antes indicados será considerada válida y auténtica, por
        lo que estas comunicaciones electrónicas, entre las partes contratantes,
        tendrán la misma fuerza legal que si hubiesen sido escritas y firmadas
        en soportes materiales.
      </Paragraph>
      <Paragraph>
        <Bold>EL CLIENTE</Bold> autoriza a <Bold>FLEXBIT</Bold> a ejecutar las
        Instrucciones recibidas a través de <Bold>LA PLATAFORMA</Bold>, con el
        uso del User ID y del Password. Si <Bold>EL CLIENTE</Bold> olvidará su
        USER ID y/o Password, deberá contactar a <Bold>FLEXBIT</Bold>
        directamente para obtener una nueva contraseña temporal. Sin perjuicio
        de lo anterior, <Bold>FLEXBIT</Bold> podrá, de tiempo en tiempo, asignar
        nuevas contraseñas de acceso a <Bold>EL CLIENTE</Bold>, lo cual será
        comunicado por <Bold>FLEXBIT</Bold> a <Bold>EL CLIENTE</Bold>{" "}
        oportunamente.
      </Paragraph>
      <Paragraph>
        b) Limitación de Responsabilidad: <Bold>EL CLIENTE</Bold> libera de toda
        responsabilidad a <Bold>FLEXBIT</Bold> derivados de los daños, pérdidas
        y perjuicios que pudiera producir el uso no autorizado del Código de
        Acceso o de la Contraseña de <Bold>EL CLIENTE</Bold> o de las Personas
        Autorizadas, igualmente, por el hecho de no revisar diariamente el
        correo electrónico que ha proporcionado para mantener comunicación con{" "}
        <Bold>FLEXBIT</Bold>.
      </Paragraph>
      <Paragraph>
        <Bold>EL CLIENTE</Bold> acepta que la totalidad de las claves que se
        generen para la utilización del servicio ofrecido en virtud de la
        celebración de este contrato son secretas, personales e intransferibles
        y por lo tanto se obliga a no divulgarlas. <Bold>EL CLIENTE</Bold>{" "}
        declara aceptar que <Bold>LA PLATAFORMA</Bold>, únicamente se puede
        acceder mediante la verificación del User ID y del Password, por lo
        tanto, al entregar <Bold>FLEXBIT</Bold>, el User ID y el Password,
        únicamente al <Bold>EL CLIENTE</Bold>, éste es el único responsable por
        todas las transacciones que se realicen a través de{" "}
        <Bold>LA PLATAFORMA</Bold> y donde se verifique el sistema dual de
        identificación, las partes entienden que fue <Bold>EL CLIENTE</Bold> que
        la realizó y en consecuencia <Bold>EL CLIENTE</Bold>
        es quien asume directamente la responsabilidad por las transacciones
        realizadas.
      </Paragraph>
      <Paragraph>
        De esta forma <Bold>EL CLIENTE</Bold>, exonera de toda responsabilidad a{" "}
        <Bold>FLEXBIT</Bold>, sus directores accionistas, trabajadores por las
        operaciones o transacciones que realice en <Bold>LA PLATAFORMA</Bold>,
        siempre que en la autorización de la transacción se hubiera suministrado
        por el correspondiente User ID y el Password. Toda transacción u
        operación en donde se hubiere verificado el User ID y el Password,
        impedirá su reversión o reclamo a <Bold>FLEXBIT</Bold>. Adicionalmente,{" "}
        <Bold>FLEXBIT</Bold> no será responsable por los daños y perjuicios que
        pudieran causar a <Bold>EL CLIENTE</Bold> o a las Personas Autorizadas
        la imposibilidad de acceder a <Bold>LA PLATAFORMA</Bold>, debido al
        olvido del Código de Acceso, de la Contraseña, o por interrupciones del
        Sistema, independientemente de la causa de estas interrupciones.
      </Paragraph>
      <Subtitle>6. SERVICIOS</Subtitle>
      <Paragraph>
        a) Modificación de los Servicios: <Bold>FLEXBIT</Bold> se reserva el
        derecho de ampliar, limitar, adicionar, eliminar o en cualquier otra
        forma modificar los Servicios prestados a través de{" "}
        <Bold>LA PLATAFORMA</Bold> en cualquier momento, sin que para ello sea
        necesario solicitar la autorización o notificar previamente a El
        CLIENTE.
      </Paragraph>
      <Paragraph>
        b) Insuficiencia de Fondos: La prestación por parte de{" "}
        <Bold>FLEXBIT</Bold> de los Servicios solicitados por{" "}
        <Bold>EL CLIENTE</Bold> o por las Personas Autorizadas a través del
        Sistema estará sujeta, entre otras cosas, a la existencia de fondos
        suficientes en la Cuenta respectiva para ejecutar la transacción
        solicitada en su totalidad. <Bold>FLEXBIT</Bold> no estará obligado a
        cumplir parcialmente una orden dada por <Bold>EL CLIENTE</Bold> o por
        las Personas Autorizadas a través de <Bold>LA PLATAFORMA</Bold>.
      </Paragraph>
      <Paragraph>
        c) Servicios brindados por <Bold>FLEXBIT</Bold> a través de LA
        PLATAFORMA:
        <Paragraph>
          i) Consulta: <Bold>EL CLIENTE</Bold> podrá revisar, los movimientos,
          saldos de las Cuentas y los créditos otorgados por{" "}
          <Bold>FLEXBIT</Bold> a favor de EL CLIENTE. La información sobre cada
          Cuenta es actualizada por <Bold>FLEXBIT</Bold> en tiempo real, con
          excepción de aquellas transacciones para las cuales{" "}
          <Bold>FLEXBIT</Bold>
          ha establecido un plazo determinado para su ejecución.{" "}
          <Bold>FLEXBIT</Bold> no es responsable por las consecuencias derivadas
          de las decisiones que realice <Bold>EL CLIENTE</Bold> en base a la
          información de las Cuentas disponible a través de{" "}
          <Bold>LA PLATAFORMA</Bold>.
        </Paragraph>
        <Paragraph>
          ii) Compraventa de Activos Digitales: <Bold>EL CLIENTE</Bold> podrá
          comprar y vender activos digitales que ofrece <Bold>FLEXBIT</Bold>.
        </Paragraph>
        <Paragraph>
          iii) Otros Servicios: <Bold>FLEXBIT</Bold> brindará los siguientes
          Servicios adicionales, sujetos a la disponibilidad de fondos en las
          Cuentas de <Bold>EL CLIENTE</Bold> y a los reglamentos internos
          aprobados por <Bold>FLEXBIT</Bold> para la ejecución de dichos
          Servicios.
        </Paragraph>
        <Paragraph>
          Las compras que se realicen a través de <Bold>LA PLATAFORMA</Bold> se
          harán mediante un débito a la cuenta de <Bold>EL CLIENTE</Bold>, por
          la cantidad indicada por éste. <Bold>FLEXBIT</Bold> comprará los
          activos digitales y pondrá en la cuenta de <Bold>EL CLIENTE</Bold> en
          LA PLATAFORMA.
        </Paragraph>
      </Paragraph>
      <Paragraph>
        d) Ejecución de los Servicios solicitados por <Bold>EL CLIENTE</Bold>:
        Con excepción de las Consultas y de aquellos Servicios que según los
        reglamentos internos de <Bold>FLEXBIT</Bold> tengan un plazo de
        ejecución mayor, los Servicios que solicite <Bold>EL CLIENTE</Bold> a
        través de LA PLATAFORMA serán ejecutados por
        <Bold>FLEXBIT</Bold> a más tardar el Día Hábil siguiente, cuando{" "}
        <Bold>FLEXBIT</Bold> reciba las Instrucciones de <Bold>EL CLIENTE</Bold>{" "}
        antes de la 10:00 a.m., hora de la República de Panamá (GMT – 5 hrs).
      </Paragraph>
      <Paragraph>
        Las Instrucciones que <Bold>FLEXBIT</Bold> reciba de{" "}
        <Bold>EL CLIENTE</Bold> con posterioridad a dicha hora o en días que no
        sean Días Hábiles, serán ejecutadas a más tardar el segundo Día Hábil
        siguiente.
      </Paragraph>
      <Paragraph>
        e) Cancelación de Instrucciones emitidas por <Bold>EL CLIENTE</Bold>:
        Las Instrucciones emitidas por <Bold>EL CLIENTE</Bold> a través de LA
        PLATAFORMA no podrán ser canceladas una vez la misma haya sido ejecutada
        por <Bold>FLEXBIT</Bold>.
      </Paragraph>
      <Subtitle>7. MEDIDAS DE SEGURIDAD</Subtitle>
      <Paragraph>
        a) Obligaciones de Seguridad de <Bold>EL CLIENTE</Bold>:
        <Paragraph>
          i) <Bold>EL CLIENTE</Bold> tomará las medidas de seguridad adecuadas
          para salvaguardar el Sistema de robo o acceso por parte de otras
          personas distintas a <Bold>EL CLIENTE</Bold> y las Personas
          Autorizadas, y además cumplirá con las disposiciones de seguridad de
          este contrato y las notificadas periódicamente por{" "}
          <Bold>FLEXBIT</Bold> a <Bold>EL CLIENTE</Bold> por escrito. EL CLIENTE
          es responsable del cumplimiento de las medidas de seguridad por parte
          de las Personas Autorizadas.
        </Paragraph>
      </Paragraph>
      <Paragraph>
        b) Violación a la Seguridad: <Bold>EL CLIENTE</Bold> notificará
        inmediatamente a<Bold>FLEXBIT</Bold> por teléfono o{" "}
        <Bold>LA PLATAFORMA</Bold> en caso de tener razones para creer que:
        <Paragraph>
          i) El User ID y el Password u otro procedimiento de autenticación
          puede haber sido puesto en peligro, manipulado, robado, colocado en un
          lugar inadecuado, perdido o utilizado incorrectamente, o alguno de
          dichos acontecimientos fue o pudo haber sido de conocimiento de alguna
          persona diferente de <Bold>EL CLIENTE</Bold> o las Personas
          Autorizadas.
        </Paragraph>
        <Paragraph>
          ii) Ha habido una falla o demora en la recepción de cualquier; o
        </Paragraph>
        <Paragraph>
          iii) Existe o se sospecha la existencia de: (a) algún error o fraude
          en la Cuenta; (b) cualquier error de programación, defecto o
          corrupción de alguna Instrucción; (c) cualquier incumplimiento real o
          potencial de cualquier disposición de seguridad de este contrato.
        </Paragraph>
        <Paragraph>
          iv) Ante la ocurrencia de cualesquiera de los hechos anteriormente
          descritos, <Bold>EL CLIENTE</Bold> se compromete a cumplir con las
          instrucciones de seguridad de <Bold>FLEXBIT</Bold>. Inmediatamente
          después de advertir una violación a la seguridad que afecte el User ID
          o el Password EL CLIENTE modificará el User ID o el Password y, al
          recibir la debida notificación, <Bold>FLEXBIT</Bold> llevará a cabo
          las acciones razonables para impedir el acceso al Sistema por medio
          del User ID o Password, <Bold>EL CLIENTE</Bold> será responsable por
          cualquier suma perdida o reclamada que surja de dicha violación a la
          seguridad o con relación a ella.
        </Paragraph>
        <Paragraph>
          v) En el caso de que las instrucciones sean dadas telefónicamente o
          mediante <Bold>LA PLATAFORMA</Bold>, <Bold>FLEXBIT</Bold> queda
          autorizado para actuar bajo instrucciones que no contengan la firma
          original de EL CLIENTE con el mismo efecto como si las instrucciones
          fueran firmadas por <Bold>EL CLIENTE</Bold>. Sin embargo,{" "}
          <Bold>FLEXBIT</Bold> puede, a su discreción, requerir la firma
          original de <Bold>EL CLIENTE</Bold> o cualquier otra documentación
          antes de actuar bajo las instrucciones.
        </Paragraph>
        <Paragraph>
          vi) <Bold>EL CLIENTE</Bold> autoriza a <Bold>FLEXBIT</Bold> a grabar
          cualquier llamada telefónica que afecte sus cuentas o los servicios
          que solicite a <Bold>FLEXBIT</Bold>, con el propósito de asegurar su
          autenticidad y veracidad, llevar un registro de dichas conversaciones,
          así como de implementar los mecanismos y procedimientos de seguridad y
          confiabilidad que estime pertinentes para la utilización de LA
          PLATAFORMA.
        </Paragraph>
      </Paragraph>
      <Subtitle>8. AUTORIZACIÓN Y EJECUCIÓN DE LAS INSTRUCCIONES</Subtitle>
      <Paragraph>
        a) Instrucciones:
        <Paragraph>
          i) Se entenderá que <Bold>FLEXBIT</Bold> ha recibido una Instrucción
          sólo cuando la recepción de dicha Instrucción haya sido reconocida por
          <Bold>LA PLATAFORMA</Bold>. Lo anterior es sin perjuicio del derecho
          discrecional de <Bold>FLEXBIT</Bold> de no ejecutar una Instrucción
          según lo establecido en la sección 8-e (Errores en el cumplimiento de
          las instrucciones /Bloqueo de acceso al Sistema).
        </Paragraph>
        <Paragraph>
          ii) <Bold>EL CLIENTE</Bold> hará todo aquello que <Bold>FLEXBIT</Bold>{" "}
          requiera y entregará a<Bold>FLEXBIT</Bold> los documentos y/o
          información que <Bold>FLEXBIT</Bold> pueda requerirle periódicamente
          para la verificación de la autenticidad y veracidad de las
          Instrucciones.
        </Paragraph>
      </Paragraph>
      <Paragraph>
        b) Confianza de <Bold>FLEXBIT</Bold> en las Instrucciones: Sin perjuicio
        de lo establecido en la Sección 8, Literal “a” (punto anterior),{" "}
        <Bold>FLEXBIT</Bold> podrá confiar categóricamente, en la autenticidad y
        debida autorización de cualquier Instrucción u otras comunicaciones que
        lleguen a <Bold>FLEXBIT</Bold> a través de <Bold>LA PLATAFORMA</Bold>, o
        que sean entregadas por <Bold>EL CLIENTE</Bold> o las Personas
        Autorizadas a <Bold>FLEXBIT</Bold> por medios electrónicos, en la forma
        especificada en el presente contrato, quedando <Bold>FLEXBIT</Bold>{" "}
        debidamente autorizado por <Bold>EL CLIENTE</Bold> a ejecutar esas
        Instrucciones y/u otras comunicaciones.
      </Paragraph>
      <Paragraph>
        c) Instrucciones incompletas: <Bold>FLEXBIT</Bold> podrá ejecutar
        Instrucciones incompletas que, en la opinión razonable de{" "}
        <Bold>FLEXBIT</Bold>, contienen información suficiente para inferir sus
        propósitos.
      </Paragraph>
      <Paragraph>
        d) Uso de intermediarios, corresponsales y sistemas de comunicaciones:
        <Bold>FLEXBIT</Bold> podrá utilizar Exchange, intermediarios y otras
        terceras partes, y podrá seleccionar y emplear sistemas de comunicación
        y de transmisión de datos para ejecutar las Instrucciones, en la
        provisión de servicios o en el desempeño de sus obligaciones conforme a
        este contrato y cualesquiera otros acuerdos con <Bold>EL CLIENTE</Bold>.
        Los derechos de <Bold>FLEXBIT</Bold>
        conforme a esta Cláusula serán en complemento de cualesquiera otros
        derechos que <Bold>FLEXBIT</Bold> pueda tener conforme a otros
        contratos, y no en contraposición de estos.
      </Paragraph>
      <Paragraph>
        e) Errores en el cumplimiento de las Instrucciones/Bloqueo de acceso al
        Sistema: En aquellas circunstancias en que <Bold>FLEXBIT</Bold>,
        actuando razonablemente, lo considere necesario o recomendable, podrá:
        <Paragraph>
          i) Optar por no ejecutar cualquiera o todas las Instrucciones; y/o
        </Paragraph>
        <Paragraph>
          ii) Impedir el acceso de <Bold>EL CLIENTE</Bold> o a las Personas
          Autorizadas a <Bold>LA PLATAFORMA</Bold>. En estos casos{" "}
          <Bold>FLEXBIT</Bold> notificará, dentro de un término prudencial, a{" "}
          <Bold>EL CLIENTE</Bold> por vía electrónica u otro medio legal
          existente entre <Bold>FLEXBIT</Bold> y <Bold>EL CLIENTE</Bold>{" "}
          explicando el hecho y los motivos que llevaron a <Bold>FLEXBIT</Bold>{" "}
          a tomar dicha decisión.
        </Paragraph>
      </Paragraph>
      <Subtitle>9. CUENTAS</Subtitle>
      <Paragraph>
        <Bold>EL CLIENTE</Bold> reconoce que cualquier Información Financiera
        y/o de cualquier otro tipo que reciba a través del Sistema de la base de
        datos de <Bold>FLEXBIT</Bold> es para el único fin de ejecutar las
        Instrucciones. <Bold>EL CLIENTE</Bold> no adquirirá derechos de ningún
        tipo o clase sobre dicha información ni tendrá derecho a usar ni copiar
        dicha información para ningún fin.
      </Paragraph>
      <Subtitle>10. RESPONSABILIDAD</Subtitle>
      <Paragraph>
        <Bold>FLEXBIT</Bold> no será responsable por cualquier pérdida derivada
        de este contrato o con respecto a <Bold>LA PLATAFORMA</Bold>{" "}
        causado-directa o indirectamente por:
      </Paragraph>
      <Paragraph>
        a) Cualquier tiempo de inactividad o falta de disponibilidad, falla,
        error de funcionamiento, distorsión o interrupción de cualquier hardware
        de computadoras, equipo, software, aplicación o de cualquier línea
        telefónica u otro Sistema de comunicación o datos, nexo de servicio,
        proveedor de servicio o equipo;
      </Paragraph>
      <Paragraph>
        b) Contaminación de virus informáticos o de programas de espionaje, de
        publicidad u otros;
      </Paragraph>
      <Paragraph>
        c) Cualquier error, discrepancia, ambigüedad, encriptación inadecuada o
        incompleta de cualquier Instrucción, información relacionada con las
        Cuentas o la Información Financiera o cualquier demora en la entrega de
        cualquiera de ellas;
      </Paragraph>
      <Paragraph>
        d) Recepción por parte de personas no autorizadas, de cualquier
        información, documentación, Instrucciones, informes o mensajes
        confidenciales u otros, referidos a <Bold>LA PLATAFORMA</Bold>, los
        Servicios o EL CLIENTE, producida por el incumplimiento de las Medidas
        de Seguridad establecidas en la Cláusula "Medidas de Seguridad”;
      </Paragraph>
      <Paragraph>
        e) Cualquier acción llevada a cabo por <Bold>FLEXBIT</Bold> conforme a
        la Cláusula 8, Literal “e” (Errores en el cumplimiento de las
        instrucciones/bloqueo de acceso al Sistema);
      </Paragraph>
      <Paragraph>
        f) Cualquier uso de <Bold>LA PLATAFORMA</Bold> para fines no autorizados
        o de manera inconsistente o en contravención de cualquiera de las
        Cláusulas de este contrato o que infrinja los derechos de un tercero;
      </Paragraph>
      <Paragraph>
        g) Cualquier hecho, acto, omisión o circunstancia fuera del control
        razonable de <Bold>FLEXBIT</Bold>, sea que dicha pérdida fuera
        razonablemente previsible para <Bold>FLEXBIT</Bold> o no.
      </Paragraph>
      <Subtitle>11. CESIÓN</Subtitle>
      <Paragraph>
        <Bold>EL CLIENTE</Bold> no podrá transferir derecho u obligación alguna
        contenida en este contrato sin el consentimiento previo y por escrito de{" "}
        <Bold>FLEXBIT</Bold>.
      </Paragraph>
      <Subtitle>12. CARGOS</Subtitle>
      <Paragraph>
        <Bold>EL CLIENTE</Bold> acepta pagar a <Bold>FLEXBIT</Bold> un cargo por
        membrecía anual que será determinado por <Bold>FLEXBIT</Bold>, en caso
        de que <Bold>FLEXBIT</Bold> así lo disponga. Además,{" "}
        <Bold>EL CLIENTE</Bold> acepta pagar todas las tarifas y comisiones
        asociadas con los productos y servicios que obtenga de{" "}
        <Bold>FLEXBIT</Bold>, incluyendo las tarifas y comisiones que
        suministrará <Bold>FLEXBIT</Bold> a <Bold>EL CLIENTE</Bold>. Queda
        entendido que
        <Bold>FLEXBIT</Bold> podrá modificar los cargos de membrecía anual y las
        tarifas y comisiones asociadas con los productos y servicios a que se
        refiere este contrato, lo cual será comunicado en la forma en que se
        establece en la Cláusula 11 de este contrato.
      </Paragraph>
      <Paragraph>
        Queda entendido y convenido que <Bold>EL CLIENTE</Bold> correrá con
        todos los impuestos, tasas, tributos o contribuciones que recaen o
        lleguen a recaer sobre las operaciones que realice a través de LA
        PLATAFORMA.
      </Paragraph>
      <Subtitle>13. DEBIDA DILIGENCIA Y AUTORIZACIÓN DE DATOS</Subtitle>
      <Paragraph>
        <Bold>EL CLIENTE</Bold> por este medio autoriza a <Bold>FLEXBIT</Bold> a
        realizar debida diligencia que contará como mínimo con la siguiente
        información: (a) Identificación Cliente; (b) Verificación de Identidad,
        (c) Verificación de Antecedentes; (d) Evaluación de Riesgo, (e) Perfil
        transaccional del Cliente, (g) cualquier otra información adicional a
        criterio de <Bold>FLEXBIT</Bold>.
      </Paragraph>
      <Paragraph>
        <Bold>EL CLIENTE</Bold> por este medio da consentimiento libre y expreso
        a <Bold>FLEXBIT</Bold>
        para recopilar, tratar, procesar y archivar toda la información
        necesaria, ya sea confidencial y/o sensitiva sobre su persona, o
        producto contratado para que <Bold>FLEXBIT</Bold> puedan: (i) cumplir
        con la presente relación contractual que aquí se contrata, así como
        cualquier otra que actualmente o en el futuro tenga{" "}
        <Bold>EL CLIENTE</Bold> con <Bold>FLEXBIT</Bold> y/o subsidiarias o
        empresas relacionadas al grupo; (ii) Realizar procesos de debida
        diligencia y cumplimiento, para así poder cumplir con las regulaciones
        relativas a las políticas de conocer a su cliente actuales y las que en
        el futuro sean promulgadas; (iii) Evaluar otros productos y/o servicios
        de <Bold>FLEXBIT</Bold> y/o subsidiarias o empresas relacionadas al
        grupo, que crea que le puedan interesar o ser conveniente para a{" "}
        <Bold>EL CLIENTE</Bold>; (iv) Enviar comunicaciones de mercadeo y demás
        información de servicios, productos y promociones de{" "}
        <Bold>FLEXBIT</Bold> y/o subsidiarias o empresas relacionadas al grupo;
        (v) Administrar promociones, encuestas y grupos de análisis, publicidad
        basada en los intereses de <Bold>EL CLIENTE</Bold>, así como realizar
        investigaciones y análisis de <Bold>FLEXBIT</Bold> y/o subsidiarias o
        empresas relacionadas al grupo; y (vi) para cualquier otra actividad que{" "}
        <Bold>EL CLIENTE</Bold> haya autorizado previamente a{" "}
        <Bold>FLEXBIT</Bold> o en el futuro o que la legislación vigente en
        materia de protección de datos privado permita.
      </Paragraph>
      <Paragraph>
        De igual manera <Bold>EL CLIENTE</Bold> autoriza expresamente a{" "}
        <Bold>FLEXBIT</Bold>, y sin requerir una autorización adicional futura,
        para traspasar y/o divulgar toda la información necesaria, ya sea
        confidencial y/o sensitiva sobre su persona y/o operaciones, a: (i)
        Subsidiarias o empresas relacionadas a <Bold>FLEXBIT</Bold>; (ii) a las
        autoridades y entes reguladores en la forma prevista por la legislación;
        (iii) a empresas de manejo de listados de historial créditos, agencias
        de Información de datos o sus similares; y (v) empresas asesoras de{" "}
        <Bold>FLEXBIT</Bold>, o de empresas de su mismo grupo tales como, pero
        sin limitar, sus abogados, contadores, calificadores de riesgo y
        empresas aseguradoras.
      </Paragraph>
      <Paragraph>
        <Bold>EL CLIENTE</Bold> autoriza irrevocablemente a <Bold>FLEXBIT</Bold>{" "}
        para que solicite y obtenga información y documentos relacionados con{" "}
        <Bold>EL CLIENTE</Bold>y sea de oficinas o funcionarios gubernamentales,
        o personas o empresas privadas, tanto nacionales como extranjeras.{" "}
        <Bold>FLEXBIT</Bold> y las personas que suministren información quedan
        relevados de toda responsabilidad por tal acción.{" "}
        <Bold>EL CLIENTE</Bold> otorga su consentimiento expreso a{" "}
        <Bold>FLEXBIT</Bold>, para que con los datos que le haya proporcionado
        y/o autorizados a obtener de terceros, pueda crear perfiles,
        evaluaciones, así como para tomar decisiones automatizadas sobre dichos
        perfiles.
      </Paragraph>
      <Paragraph>
        Durante la vigencia del presente contrato, <Bold>EL CLIENTE</Bold>{" "}
        tendrá siempre el derecho a solicitarle a <Bold>FLEXBIT</Bold>, el
        acceso a su información, su corrección y eliminación, de la forma y
        medios establecidos en las políticas de privacidad en materia de
        protección de datos.
      </Paragraph>
      <Subtitle>
        14. DECLARACIÓN DE{" "}
        <Bold>
          <Bold>EL CLIENTE</Bold>
        </Bold>
        :
      </Subtitle>
      <Paragraph>
        <Bold>EL CLIENTE</Bold> certifica que todos sus fondos son producto de
        actividades comerciales lícitas. Adicionalmente, declara{" "}
        <Bold>EL CLIENTE</Bold> que conoce y se hace responsable del origen
        legal de los fondos con los cuales realizará transacciones con{" "}
        <Bold>FLEXBIT</Bold>. Si <Bold>EL CLIENTE</Bold> llegare a ser: (a)
        vinculado, por parte de las autoridades nacionales o internacionales
        competentes, a cualquier tipo de investigación por delitos relacionados
        con drogas, tales como pero sin limitarse a tráfico de drogas,
        narcotráfico, terrorismo, secuestro, lavado de activos, financiación del
        terrorismo y/o administración de recursos relacionados con actividades
        terroristas u otros delitos relacionados con el lavado de activos y
        financiación del terrorismo; (b) incluidos en listas para el control de
        lavado de activos y financiación del terrorismo administradas por
        cualquier autoridad nacional o extranjera, tales como la lista de la
        Oficina de Control de Activos en el Exterior (OFAC) emitida por la
        Oficina del Tesoro de los Estados Unidos de América, la lista de la
        Organización de las Naciones Unidas y otras listas públicas relacionadas
        con el tema del lavado de activos y financiación del terrorismo; o (c)
        condenados por parte de las autoridades nacionales o internacionales
        competentes en cualquier tipo de proceso judicial relacionado con la
        comisión de los anteriores delitos en cualquier jurisdicción, se
        entenderá que <Bold>EL CLIENTE</Bold> ha incumplido el presente
        Contrato.
      </Paragraph>
      <Paragraph>
        También se entenderá que <Bold>EL CLIENTE</Bold> ha incumplido este
        Contrato si cualquiera de sus representantes legales se vincula a
        cualquier investigación por parte de las autoridades nacionales o
        internacionales competentes, o se comprueba mediante fallo judicial que
        han incurrido en algunas de las conductas tipificadas como delitos de
        Blanqueo de Capitales en el Código Penal.
      </Paragraph>
      <Paragraph>
        Igualmente, <Bold>EL CLIENTE</Bold> declara que conoce y está en
        cumplimiento con, no ha previamente infringido, y no ha sido acusado de,
        ni condenado por, haber violado la Ley 23 de 27 de abril de 2015, que
        adopta medidas para prevenir el blanqueo de capitales, el financiamiento
        del terrorismo y el financiamiento de la proliferación de armas de
        destrucción masiva y dicta otras disposiciones, al igual que el Decreto
        Ejecutivo 35 de 8 de septiembre de 2022, por medio del cual se
        reglamenta la Ley 23 de 27 de abril de 2015 y cualquier modificación
        futura.
      </Paragraph>
      <Paragraph>
        El incumplimiento por parte de <Bold>EL CLIENTE</Bold>, de acuerdo con
        lo estipulado en esta cláusula, dará lugar a que <Bold>FLEXBIT</Bold>{" "}
        declare este Contrato resuelto de pleno derecho sin necesidad de previa
        declaración judicial de autoridad competente.
      </Paragraph>
      <Subtitle>15. JURISDICCIÓN Y COMPETENCIA: </Subtitle>
      <Paragraph>
        Este contrato se regirá por sus propios términos y condiciones, los
        cuales <Bold>EL CLIENTE</Bold> declara conocer y acepta, por las
        regulaciones de
        <Bold>FLEXBIT</Bold> que se encuentren vigentes a la firma del presente
        contrato, por los contratos aplicables que <Bold>FLEXBIT</Bold>{" "}
        establezca en el futuro y por las Leyes de la República de Panamá.
      </Paragraph>
      <Paragraph>
        Acuerdan <Bold>LAS PARTES</Bold> que cualquier controversia,
        discrepancia, litigio, disputa, reclamo o diferencia originada en
        relación con la ejecución, validez existencia, aplicabilidad, nulidad,
        resolución, terminación o interpretación de este acuerdo o de cualquier
        otra materia vinculada o contenida en él, será resuelta definitivamente
        mediante un Arbitraje de Derecho de conformidad con el reglamento de{" "}
        <Bold>
          CONCILIACIÓN Y ARBITRAJE DEL CENTRO DE ARBITRAJE DE LA CÁMARA DE
          COMERCIO Y AGRICULTURA DE PANAMÁ (CECAP).
        </Bold>
      </Paragraph>
      <Paragraph>
        <Bold>LAS PARTES</Bold> contratantes aceptan de manera expresa que el
        laudo arbitral será definitivo y obligatorio, renunciando a interponer
        cualquier medio de impugnación contra el laudo arbitral. El Laudo
        Arbitral determinará la forma en que deben satisfacerse los gastos
        relacionados con el arbitraje.
      </Paragraph>
      <Paragraph>
        La Ley Aplicable a la validez, efectos, así como cualquier otro asunto
        vinculado al arbitraje y a la materia del mismo será la Ley Panameña.
        <Bold>LAS PARTES</Bold> en general, harán todos sus esfuerzos para que
        mantengan vigente el presente compromiso arbitral, respondiendo por los
        perjuicios de su incumplimiento. Asimismo, renuncian a cualquier acción
        o excepción destinada a limitarlo o anularlo.
      </Paragraph> */}
      {/* <br />
      <br />
      De acuerdo con todo lo previamente pactado, <Bold> <Bold><Bold>EL CLIENTE</Bold></Bold> </Bold> */}
    </div>
  );
};

export default PlatformContract;

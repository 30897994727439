import React from "react";
import {
  Paragraph,
  Center,
  Subtitle,
  Bold,
  HiperLink,
  ListNumber,
  ItemList,
  ItemText,
  ListNormal,
  Title,
} from "./styles";

const Faqs = () => {
  return (
    <>
      <Title>Sección 1: Registro y Activación de Cuenta</Title>
      <ListNumber>
        <ItemList>
          <ItemText>¿Qué es FlexBit y para qué sirve?</ItemText>
          <Paragraph>
            FlexBit es una plataforma que permite la gestión de billeteras de
            criptomonedas y el uso de tarjetas virtuales y físicas para realizar
            pagos.
            <br></br>
            <HiperLink
              href="https://www.youtube.com/watch?v=CImC_Laff04"
              target="_blank"
            >
              Ver Video
            </HiperLink>
          </Paragraph>
        </ItemList>
        <ItemList>
          <ItemText>¿Cómo me registro en FlexBit?</ItemText>
          <Paragraph>
            Recibes un correo con un enlace de activación, ingresas con el mismo
            email registrado en SMART y sigues los pasos para activar una
            cuenta.
            <br></br>
            <HiperLink
              href="https://www.youtube.com/watch?v=X7Qp4OpTDTs&t=3s"
              target="_blank"
            >
              Ver Video tutorial
            </HiperLink>
            <br></br>
            <br></br>O visita el enlace y sigue los pasos{" "}
            <HiperLink
              href="https://login.flexbit.app/auth/request_activation"
              target="_blank"
            >
              https://login.flexbit.app/auth/request_activation
            </HiperLink>
          </Paragraph>
        </ItemList>
        <ItemList>
          <ItemText>
            ¿Debo usar el mismo correo que en SMART para registrarme?
          </ItemText>
          <Paragraph>
            Sí, de no tener acceso a ese mail tendrías que ponerte en contacto
            con SMART para saber cuál es tu correo o en todo caso cambiarlo.
          </Paragraph>
        </ItemList>
        <ItemList>
          <ItemText>¿Qué hago si el correo de activación no me llega?</ItemText>
          <Paragraph>
            Revisa la carpeta de spam. Si no está allí, escriba al chat de
            soporte o envíe un correo a “clientes@flexbit.app”
          </Paragraph>
        </ItemList>
        <ItemList>
          <ItemText>
            ¿Dónde reviso si el correo de activación está en spam?
          </ItemText>
          <Paragraph>
            En tu bandeja de entrada en tu correo, busca en la carpeta de "Spam"
            o "Correo no deseado".
          </Paragraph>
        </ItemList>
        <ItemList>
          <ItemText>¿Cómo activo mi cuenta en FlexBit?</ItemText>
          <Paragraph>
            Ingresa al enlace del correo, coloca tu email registrado en SMART y
            haz clic en "Activar Cuenta".
            <br></br>
            <HiperLink
              href="https://www.youtube.com/watch?v=X7Qp4OpTDTs&t=3s"
              target="_blank"
            >
              Ver Video tutorial
            </HiperLink>
          </Paragraph>
        </ItemList>
        <ItemList>
          <ItemText>
            ¿Puedo registrarme con un correo diferente al de SMART?
          </ItemText>
          <Paragraph>
            No, debes usar el mismo correo asociado a tu cuenta SMART.
          </Paragraph>
        </ItemList>
        <ItemList>
          <ItemText>
            ¿Por qué necesito activar mi cuenta antes de usar la plataforma?
          </ItemText>
          <Paragraph>
            Para poder tener acceso y corroborar que eres usuario de SMART
          </Paragraph>
        </ItemList>
        <ItemList>
          <ItemText>¿Qué datos debo ingresar al activar mi cuenta?</ItemText>
          <Paragraph>
            Solo tu correo registrado en SMART y una contraseña segura.
          </Paragraph>
        </ItemList>
        <ItemList>
          <ItemText>
            ¿Necesito verificar mi identidad (KYC) al registrarme?
          </ItemText>
          <Paragraph>No, pero sí cuando solicites tu Flexcard.</Paragraph>
        </ItemList>
      </ListNumber>

      <Title>Sección 2: Inicio de Sesión y Seguridad</Title>
      <ListNumber start={11}>
        <ItemList>
          <ItemText>¿Cómo crear una contraseña segura para mi cuenta?</ItemText>
          <Paragraph>
            Debe contener al menos<Bold> 8 caracteres</Bold>, incluir{" "}
            <Bold>mayúsculas, minúsculas, números y símbolos.</Bold>
          </Paragraph>
        </ItemList>
        <ItemList>
          <ItemText>¿Qué hago si olvido mi contraseña?</ItemText>
          <Paragraph>
            Usa la opción <Bold>"Olvidé mi contraseña"</Bold> en la página de
            inicio de sesión.
          </Paragraph>
        </ItemList>
        <ItemList>
          <ItemText>¿Puedo cambiar mi correo registrado en FlexBit?</ItemText>
          <Paragraph>No, la cuenta está vinculada a tu correo SMART.</Paragraph>
        </ItemList>
        <ItemList>
          <ItemText>
            ¿Cómo protejo mi cuenta contra accesos no autorizados?
          </ItemText>
          <Paragraph>
            Activa la <Bold>autenticación en dos factores (2FA)</Bold> y no
            compartas tus credenciales.
          </Paragraph>
        </ItemList>
        <ItemList>
          <ItemText>¿FlexBit tiene autenticación en dos pasos (2FA)?</ItemText>
          <Paragraph>
            Sí, se recomienda activarla desde la configuración de tu cuenta.
          </Paragraph>
        </ItemList>
        <ItemList>
          <ItemText>
            ¿Cómo activar la autenticación en dos pasos en mi cuenta?
          </ItemText>
          <Paragraph>
            Entra a tu perfil de usuario y sigue las instrucciones en{" "}
            <Bold>"Seguridad"</Bold>.
          </Paragraph>
        </ItemList>
        <ItemList>
          <ItemText>¿Qué dispositivos son compatibles con FlexBit?</ItemText>
          <Paragraph>
            Smartphones, computadores de escritorio y laptops
          </Paragraph>
        </ItemList>
        <ItemList>
          <ItemText>¿Puedo iniciar sesión desde varios dispositivos?</ItemText>
          <Paragraph>
            Sí, pero se recomienda mantener la seguridad habilitada
          </Paragraph>
        </ItemList>
        <ItemList>
          <ItemText>
            ¿Qué hago si mi cuenta fue bloqueada por intentos fallidos?
          </ItemText>
          <Paragraph>
            Comunicarse con el equipo de atención a clientes con un mail a
            “clientes@flexbit.app”
          </Paragraph>
        </ItemList>
        <ItemList>
          <ItemText>¿Cómo cierro sesión en todos los dispositivos?</ItemText>
          <Paragraph>
            Desde la configuración de tu cuenta, selecciona{" "}
            <Bold>"Cerrar sesión en todos los dispositivos".</Bold>
          </Paragraph>
        </ItemList>
      </ListNumber>

      <Title>Sección 3: Creación y Uso de Billeteras</Title>
      <ListNumber start={21}>
        <ItemList>
          <ItemText>¿Cuántas billeteras debo crear en FlexBit?</ItemText>
          <Paragraph>
            Debes crear tres:{" "}
            <Bold>Bitcoin (BTC), Tether (USDT) y CNKT (Red Polygon)</Bold>. Si
            eres socio de Smart, solo debes activar tu cuenta y tendrás tus
            billeteras creadas.
          </Paragraph>
        </ItemList>
        <ItemList>
          <ItemText>¿Cómo crear una billetera en la plataforma?</ItemText>
          <Paragraph>
            Inicia sesión, ve a <Bold>Billeteras</Bold>, ingresa en cada una de
            las Wallet y da click en crear
            <br></br>
            <HiperLink
              href="https://www.youtube.com/watch?v=6Uxq9eyt6CY"
              target="_blank"
            >
              Ver Video
            </HiperLink>
          </Paragraph>
        </ItemList>
        <ItemList>
          <ItemText>¿Puedo usar FlexBit sin una billetera?</ItemText>
          <Paragraph>No, necesitas crear las tres billeteras.</Paragraph>
        </ItemList>
        <ItemList>
          <ItemText>¿Qué criptomonedas son compatibles con FlexBit?</ItemText>
          <Paragraph>
            Dentro del ecosistema de Flexbit el usuario tendrá una billetera
            para cada criptomoneda{" "}
            <Bold>
              Bitcoin (BTC), Tether (USDT) SÒLO EN LA RED TRON y CNKT en Polygon
            </Bold>
            .
          </Paragraph>
        </ItemList>
        <ItemList>
          <ItemText>¿Dónde veo la dirección de mi billetera?</ItemText>
          <Paragraph>
            En la sección <Bold>Billeteras</Bold>, haciendo clic en{" "}
            <Bold>"Ver"</Bold> y luego <Bold>“Recibir”</Bold>, te aparecerá la
            dirección de tu billetera.
          </Paragraph>
        </ItemList>
        <ItemList>
          <ItemText>¿Puedo crear solo una billetera en lugar de tres?</ItemText>
          <Paragraph>
            No, debes crear las tres para habilitar tu cuenta.
          </Paragraph>
        </ItemList>
        <ItemList>
          <ItemText>¿Cómo elimino una billetera en FlexBit?</ItemText>
          <Paragraph>
            Actualmente, no se pueden eliminar las billeteras.
          </Paragraph>
        </ItemList>
        <ItemList>
          <ItemText>
            ¿Cómo transfiero fondos entre mis propias billeteras en FlexBit?
          </ItemText>
          <Paragraph>
            No se transfieren fondos entre billeteras, es posible realizar
            intercambios entre las criptomonedas con las que trabajamos, de BTC
            o USDT a CNKT o de CNKT a BTC o USDT.
          </Paragraph>
        </ItemList>
        <ItemList>
          <ItemText>¿Cómo verifico mi saldo en cada billetera?</ItemText>
          <Paragraph>
            En la sección <Bold>Billeteras</Bold>, donde se muestra el saldo
            disponible.
          </Paragraph>
        </ItemList>
        <ItemList>
          <ItemText>
            ¿Las billeteras de FlexBit tienen tarifas de mantenimiento?
          </ItemText>
          <Paragraph>Si, es el equivalente a $9.99 USD mensuales.</Paragraph>
        </ItemList>
      </ListNumber>

      <Title>Sección 4: Depósitos y Fondeo de Billetera</Title>
      <ListNumber start={31}>
        <ItemList>
          <ItemText>¿Cómo deposito fondos en mi billetera de FlexBit?</ItemText>
          <Paragraph>
            Ingresa a <Bold>Billeteras</Bold>, elige{" "}
            <Bold>"Añadir Fondos"</Bold> y sigue las instrucciones.
            <br></br>
            <HiperLink
              href="https://www.youtube.com/watch?v=CJAs2APa3tw"
              target="_blank"
            >
              Ver Tutorial
            </HiperLink>
          </Paragraph>
        </ItemList>
        <ItemList>
          <ItemText>
            ¿Qué métodos de fondeo están disponibles en FlexBit?
          </ItemText>
          <Paragraph>
            <Bold>Criptomonedas (BTC, USDT, CNKT).</Bold>
          </Paragraph>
        </ItemList>
        <ItemList>
          <ItemText>¿Puedo fondear con dinero fiat?</ItemText>
          <Paragraph>Actualmente, no está disponible.</Paragraph>
        </ItemList>
        <ItemList>
          <ItemText>
            ¿Cuánto tiempo tarda en acreditarse un depósito en FlexBit?
          </ItemText>
          <Paragraph>
            Depende de la congestión de la red blockchain, normalmente{" "}
            <Bold>unos minutos</Bold>.
          </Paragraph>
        </ItemList>
        <ItemList>
          <ItemText>
            ¿Qué hago si mi depósito no se refleja en mi cuenta?
          </ItemText>
          <Paragraph>
            Verifica la transacción en la blockchain y contacta al soporte de
            FlexBit enviando un mail a clientes@flexbit.app
          </Paragraph>
        </ItemList>
        <ItemList>
          <ItemText>¿Puedo fondear con diferentes redes blockchain?</ItemText>
          <Paragraph>
            Para USDT trabajamos con ERC-20 Tron, Bitcoin con su propia red y
            CNKT con Polygon
          </Paragraph>
        </ItemList>
        <ItemList>
          <ItemText>
            ¿Cómo obtengo la dirección de mi billetera para recibir
            criptomonedas?
          </ItemText>
          <Paragraph>
            En la sección <Bold>Billeteras</Bold>, haciendo clic en{" "}
            <Bold>"Ver"</Bold> y luego “Recibir”, te aparecerá la dirección de
            tu billetera
            <Bold>recuerda copiar correctamente esa dirección</Bold>
          </Paragraph>
        </ItemList>
        <ItemList>
          <ItemText>¿Cómo evitar perder fondos en una transacción?</ItemText>
          <Paragraph>Usa siempre la red y criptomoneda correctas.</Paragraph>
        </ItemList>
        <ItemList>
          <ItemText>
            ¿FlexBit cobra comisiones por recibir criptomonedas?
          </ItemText>
          <Paragraph>No, pero puede haber tarifas de red.</Paragraph>
        </ItemList>
        <ItemList>
          <ItemText>
            ¿Qué hacer si envié fondos a una dirección incorrecta?
          </ItemText>
          <Paragraph>
            Lamentablemente, <Bold>no se pueden recuperar</Bold>.
          </Paragraph>
        </ItemList>
        <ItemList>
          <ItemText>¿Existen Limites en el uso de la billetera?</ItemText>
          <Paragraph>
            Si, el limite es 2,500 USDT y 2,500 dólares en BTC.
          </Paragraph>
        </ItemList>
      </ListNumber>

      <Title>Sección 5: Solicitud y Uso de la Tarjeta</Title>
      <ListNumber start={42}>
        <ItemList>
          <ItemText>¿Cómo solicito mi tarjeta FlexBit?</ItemText>
          <Paragraph>
            Entra a <Bold>Flex Card </Bold>y haz clic en{" "}
            <Bold>"Solicitar Flex Card"</Bold>.<br></br>
            <HiperLink
              href="https://www.youtube.com/watch?v=C2IDqTibVwA&t=3s"
              target="_blank"
            >
              Ver video Tutorial
            </HiperLink>
          </Paragraph>
        </ItemList>
        <ItemList>
          <ItemText>¿Qué documentos necesito para completar el KYC?</ItemText>
          <Paragraph>
            En el caso de ciudadanos mexicanos pueden ser; documento de
            identidad INE; pasaporte, licencia de conducir (todo esto tiene que
            ser en físico), selfie y dirección. En caso de no ser mexicano,
            pasaporte obligatoriamente
          </Paragraph>
        </ItemList>
        <ItemList>
          <ItemText>
            ¿Cuánto tiempo tarda en aprobarse mi solicitud de tarjeta?
          </ItemText>
          <Paragraph>De 24 a 48 horas.</Paragraph>
        </ItemList>
        <ItemList>
          <ItemText>¿Qué documentos necesito para completar el KYC?</ItemText>
          <Paragraph>
            En el caso de ciudadanos mexicanos pueden ser; documento de
            identidad INE; pasaporte, licencia de conducir (todo esto tiene que
            ser en físico), selfie y dirección. En caso de no ser mexicano,
            pasaporte obligatoriamente
          </Paragraph>
        </ItemList>
        <ItemList>
          <ItemText>¿Cómo agrego mi tarjeta a Apple Pay o Google Pay?</ItemText>
          <Paragraph>Aún no está habilitada la función.</Paragraph>
        </ItemList>
        <ItemList>
          <ItemText>¿Cómo recargo fondos en mi tarjeta?</ItemText>
          <Paragraph>
            En <Bold>Flex Card</Bold>, haz clic en <Bold>"Abonar"</Bold>.
          </Paragraph>
        </ItemList>
        <ItemList>
          <ItemText>
            ¿Cuáles son las comisiones por abonar fondos a la tarjeta?
          </ItemText>
          <Paragraph>
            La comisión por abono a la tarjeta es de 5% del monto abonado.
          </Paragraph>
        </ItemList>
        <ItemList>
          <ItemText>¿Puedo usar mi tarjeta FlexBit en cualquier país?</ItemText>
          <Paragraph>Sí, en comercios que acepten pagos con tarjeta.</Paragraph>
        </ItemList>
        <ItemList>
          <ItemText>
            ¿Qué hago si mi tarjeta no funciona en una compra?
          </ItemText>
          <Paragraph>
            Verifica saldo y contacta soporte enviando un mail a
            clientes@flexbit.app
          </Paragraph>
        </ItemList>
        <ItemList>
          <ItemText>
            ¿Cómo bloquear mi tarjeta en caso de robo o extravío?
          </ItemText>
          <Paragraph>
            Entrar a FlexBit, ir al apartado de Flexcard y en la opción
            <Bold> "Bloquear"</Bold> sobre la tarjeta que desees (Virtual o
            física).
          </Paragraph>
        </ItemList>
        <ItemList>
          <ItemText>¿Existe un limite en el uso de la tarjeta?</ItemText>
          <Paragraph>
            Si, 5,000 USD es el limite mensual de uso en la tarjeta.
          </Paragraph>
        </ItemList>
        <ItemList>
          <ItemText>¿Cómo contacto con el soporte de FlexBit?</ItemText>
          <Paragraph>
            Desde el chat de soporte o el correo de soporte clientes@flexbit.app
          </Paragraph>
        </ItemList>
        <ItemList>
          <ItemText>
            Ya tengo cuenta en Fëanor, ¿Cómo traspasar mi dinero de mis
            billeteras?
          </ItemText>
          <Paragraph>
            Fëanor y FlexBit son plataformas distintas, si quieres pasar fondos
            desde Fëanor a FlexBit, debes hacer el envío desde tu billetera de
            Fëanor a tu dirección de billetera de FlexBit.
          </Paragraph>
        </ItemList>
        <ItemList>
          <ItemText>
            ¿Cuánto tardará en llegar mi tarjeta digital y física?
          </ItemText>
          <Paragraph>
            La tarjeta digital la tiene al momento una vez se hace la solicitud
            de su tarjeta. La tarjeta física le estaría llegando de 15 a 20 días
            después de su solicitud.
          </Paragraph>
        </ItemList>
      </ListNumber>
    </>
  );
};

export default Faqs;

import React, { useEffect } from "react";
import { withRouter } from "react-router";
import Styled from "./styles";
import {
  BlackContainer,
  TitleBlackSection,
  SectionDividerWithTitle,
  Header1White,
  BottomLineGreen,
  VioletContainer,
} from "styles/common/index.js";
import heroImg from "media/heros/meet_bitcoin_gold.png";
import { Helmet } from "react-helmet";
import ContactUs from "pages/MeetFeanor/ContactUs";
import sergio from "images/sergio.jfif";
import walter from "images/walter.jfif";
// import belisario from "images/belisario.jfif";
import imgFabian from "images/fabian@2x.png";
// import gabriela from "images/gabriela.jpg";
// import ibrahim from "images/ibrahim.jpg";
import { faLinkedin } from "@fortawesome/free-brands-svg-icons";
import { library } from "@fortawesome/fontawesome-svg-core";
library.add(faLinkedin);

const MeetBTC = ({ history }) => {
  // Scroll to top on all pages
  const scrollTop = () => {
    window.scrollTo(0, 0);
  };

  useEffect(() => {
    scrollTop();
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <Styled.VioletContainer_useCases>
        <Styled.HeroLeft>
          <div style={{ marginBottom: "40px" }}>
            <Styled.TaglineAbout>
              <TitleBlackSection>Conoce FlexBit</TitleBlackSection>
            </Styled.TaglineAbout>
            <Styled.SubtaglineHome>
              <Styled.DescriptionBlackSection_useCases>
                Somos una compañía líder en soluciones Fintech, ofreciendo
                servicios personales y empresariales con tecnología de última
                generación.
              </Styled.DescriptionBlackSection_useCases>
            </Styled.SubtaglineHome>
          </div>
          <div>
            <Styled.TaglineAbout>
              <TitleBlackSection>Nuestra meta</TitleBlackSection>
            </Styled.TaglineAbout>
            <Styled.SubtaglineHome>
              <Styled.DescriptionBlackSection_useCases>
                Generar nuevas oportunidades de negocio y seguir revolucionando
                el futuro financiero digital, materializando tus ideas y tu
                futuro con total transparencia.
              </Styled.DescriptionBlackSection_useCases>
            </Styled.SubtaglineHome>
          </div>
        </Styled.HeroLeft>
        <Styled.HeroRight src={heroImg}></Styled.HeroRight>
      </Styled.VioletContainer_useCases>

      <ContactUs showLineBottom={false}></ContactUs>

      <Header1White>Nuestro equipo </Header1White>

      <Styled.ContainerTextTeam>
        <Styled.DescriptionTeam>
          <Styled.SectionTextCentered>
            Con una amplia experiencia en pagos y desarrollo de software, el
            equipo de FlexBit está preparado para tokenizar tus activos, mejorar
            los estándares de la industria y potenciar el uso de la tecnología
            blockchain en Latinoamérica y el Caribe. En cada proyecto hay un
            equipo comprometido, protegiendo siempre tus intereses.
          </Styled.SectionTextCentered>
        </Styled.DescriptionTeam>
        {/* <Styled.ContainerMembers>         
          <Styled.ContainerTeamMember>
            <Styled.ImageMember src={walter} />
            <Styled.TeamMemberName>Walter Alvarez Sack</Styled.TeamMemberName>
            <Styled.TeamMemberPosition>
              CFO - Co Founder
            </Styled.TeamMemberPosition>

            <Styled.TeamMemberDescription>
              <Styled.LinkSocial
                target="_blank"
                href="https://www.linkedin.com/in/walter-alvarez-sack-17662984/"
              >
                <Styled.SocialIcon icon={faLinkedin} />
              </Styled.LinkSocial>
            </Styled.TeamMemberDescription>
          </Styled.ContainerTeamMember>

          <Styled.ContainerTeamMember>
            <Styled.ImageMember src={imgFabian} />
            <Styled.TeamMemberName>Fabian Chiera</Styled.TeamMemberName>
            <Styled.TeamMemberPosition>
              CTO - Co Founder
            </Styled.TeamMemberPosition>

            <Styled.TeamMemberDescription>
              <Styled.LinkSocial
                target="_blank"
                href="https://www.linkedin.com/in/fabianchiera/"
              >
                <Styled.SocialIcon icon={faLinkedin} />
              </Styled.LinkSocial>
            </Styled.TeamMemberDescription>
          </Styled.ContainerTeamMember>

          <Styled.ContainerTeamMember>
            <Styled.ImageMember src={sergio} />
            <Styled.TeamMemberName>Sergio Terstusio</Styled.TeamMemberName>
            <Styled.TeamMemberPosition>
              Director FlexBit
            </Styled.TeamMemberPosition>
            <Styled.TeamMemberDescription>
              <Styled.LinkSocial
                target="_blank"
                href="https://www.linkedin.com/in/sergiotertusio/"
              >
                <Styled.SocialIcon icon={faLinkedin} />
              </Styled.LinkSocial>
            </Styled.TeamMemberDescription>
          </Styled.ContainerTeamMember>         
        </Styled.ContainerMembers>        */}
      </Styled.ContainerTextTeam>

      <SectionDividerWithTitle>
        Tú eres parte del cambio
      </SectionDividerWithTitle>

      <VioletContainer>
        <Styled.SectionTextCentered light={true}>
          Gestionamos riesgos con experiencia y precisión. Con un equipo
          proveniente de la banca tradicional que reconoce los avances de la
          tecnología aplicamos la debida diligencia y contamos con oficiales de
          cumplimiento para garantizar operaciones seguras y transparentes.
        </Styled.SectionTextCentered>
      </VioletContainer>

      <Helmet>
        <meta charSet="utf-8" />
        <title>
          {" "}
          Conoce FlexBit - Aprende todo sobre el dinero del futuro | FlexBit
        </title>
        <meta
          name="description"
          content="Esta guía te enseñará todo lo necesario: qué es Bitcoin, Cómo funciona, su valor y cuáles son sus principales características."
        />
      </Helmet>
    </>
  );
};

export default withRouter(MeetBTC);

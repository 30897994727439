import React from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import Header from "./components/Header/index.js";
import About from "./pages/About/index.js";
// import Policy from "./pages/Policy/index.js";
import KYC from "./pages/KYC/index.js";
import TermsAndConditions from "./pages/Terms/index.js";
// import Help from "./pages/Help/index.js";
// import HowToStart from "./pages/HowToStart/index.js";
import MeetBTC from "./pages/MeetFeanor/index.js";
// import Developers from "./pages/Developers/index.js";
// import Fees from "./pages/Fees/index.js";
// import Responsability from "./pages/Responsability/index.js";
import Empresas from "./pages/Empresas/index.js";
import Footer from "./components/Footer/index.js";
import Home from "./pages/Home/index.js";
// import Auth from "./pages/Auth/index.js";
import NotFound from "./pages/NotFound";
import { AuthProvider } from "./Auth";
import { CurrencyProvider } from "./CurrencyContext";
import { BtcPriceProvider } from "./BtcPriceContext";

import Wallet from "./pages/Wallet/index.js";

import Styles from "./styles/Macro";
import "styles/all.css";
import Tokenizacion from "pages/Tokenizacion/index.js";
import Popup from "components/popup/Popup.jsx";
import TelegramButton from "components/TelegramButton.js";
import Faq from "pages/Faq/index.js";

const Main = () => {
  return (
    <AuthProvider>
      <CurrencyProvider>
        <BtcPriceProvider>
          <BrowserRouter>
            <Styles.AppContainer>
              {/* quitando telegram */}
              {/* <TelegramButton /> */}
              <Header />
              <Switch>
                <Route exact path="/" component={Home} />
                {/* <Route exact path="/ayuda" component={Help} /> */}
                {/* <Route exact path="/como-empezar" component={HowToStart} /> */}
                {/* <Route exact path="/comisiones" component={Fees} /> */}
                <Route exact path="/nosotros" component={About} />
                {/* <Route
                  exact
                  path="/politica-de-privacidad"
                  component={Policy}
                /> */}
                <Route exact path="/politicas-kyc-aml" component={KYC} />
                <Route
                  exact
                  path="/terminos-y-condiciones"
                  component={TermsAndConditions}
                />
                <Route exact path="/quienes-somos" component={MeetBTC} />
                {/* <Route exact path="/desarrolladores" component={Developers} /> */}
                {/* <Route
                  exact
                  path="/responsabilidad-social"
                  component={Responsability}
                /> */}
                {/* <Route exact path="/empresas" component={Empresas} /> */}
                <Route exact path="/wallet" component={Wallet} />
                <Route exact path="/faq" component={Faq} />
                <Route
                  exact
                  path="/tokenizacion-activos"
                  component={Tokenizacion}
                />

                <Route component={NotFound} />
              </Switch>
              {/* popup de información */}
              {/* <Popup /> */}
              <Footer />
            </Styles.AppContainer>
          </BrowserRouter>
        </BtcPriceProvider>
      </CurrencyProvider>
    </AuthProvider>
  );
};

export default Main;

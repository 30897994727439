import styled from "styled-components";
import { Link } from "react-router-dom";
import colors from "styles/common/colors";

export const AboutContainer = styled.div`
  width: 100%;
  padding: 100px;
  padding-top: 20vh !important;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background: rgb(236, 243, 248);
  background: linear-gradient(
    180deg,
    rgba(236, 243, 248, 1) 0%,
    rgba(236, 243, 248, 1) 50%,
    rgba(255, 255, 255, 0) 100%
  );
  @media (max-width: 640px) {
    padding: 20px;
  }
`;

export const StepContainer = styled.div`
  width: 100%;
  padding: 2px 150px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  background: black;
  @media (max-width: 640px) {
    padding: 20px;
    flex-direction: column;
  }
`;

export const ContainerStepLeft = styled.section`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 0;
  width: 20%;
  color: ${colors.blue_agente_btc};

  @media (max-width: 640px) {
    padding: 0;
    flex-wrap: wrap;
    justify-content: center;
    width: 100%;
  }
`;

export const MissionContainer = styled.div`
  width: 100%;
  padding: 10px 100px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  background: white;
  @media (max-width: 640px) {
    padding: 20px;
    flex-direction: column;
  }
`;

export const TeamContainer = styled.div`
  width: 100%;
  padding: 0 10px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: relative;
  background: ${colors.flexbit_gray_bg};
  @media (max-width: 640px) {
    padding: 20px;
  }
`;

const TaglineAbout = styled.h5`
  font-family: "Bahnschift Light", sans-serif;
  font-size: 35px;
  font-weight: normal;
  margin: 0;
  font-weight: 700;
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  @media screen and (max-width: 640px) {
    font-size: 18px;
  }
`;

const SubtaglineHome = styled.h5`
  font-family: Montserrat, sans-serif;
  font-size: 25px;
  margin: 0;
  font-weight: 300;
  margin-top: 35px;
  margin-right: 100px;
  @media screen and (max-width: 640px) {
    margin-top: 10px;
    font-size: 15px;
    font-weight: 200;
    margin-right: 0px;
  }

  @media screen and (max-width: 320px) {
    margin-top: 10px;
    font-size: 13px;
  }
`;

const TeamMemberName = styled.h5`
  font-family: Montserrat, sans-serif;
  font-size: 18px;
  font-weight: normal;
  margin: 0;
  color: ${colors.dark_blue_agente_btc};
  font-weight: 700;
  margin-top: 10px;
  @media screen and (max-width: 640px) {
    font-size: 13px;
  }
`;

const TeamMemberPosition = styled.h5`
  font-family: "Bahnschift Light", sans-serif;
  font-size: 22px;
  font-weight: normal;
  margin: 0;
  color: ${colors.feanor_orange};
  font-weight: 700;
  margin-top: 20px;
  @media screen and (max-width: 640px) {
    font-size: 13px;
  }
`;

const TeamMemberDescription = styled.h5`
  font-family: "Bahnschift Light", sans-serif;
  font-size: 18px;
  margin: 0;
  color: black;
  font-weight: 300;
  margin-top: 25px;
  @media screen and (max-width: 640px) {
    margin-top: 10px;
    font-size: 13px;
    font-weight: 200;
    margin-right: 0px;
  }

  @media screen and (max-width: 320px) {
    margin-top: 10px;
    font-size: 13px;
  }
`;

export const HeroSection = styled.section`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 48px 0;

  @media (max-width: 640px) {
    padding: 0;
    flex-wrap: wrap;
    justify-content: center;
  }
`;

export const ContainerTextTeam = styled.section`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 48px 0;
  flex-direction: column;
  text-align: center;
  @media (max-width: 640px) {
    padding: 0;
    flex-wrap: wrap;
    justify-content: center;
  }
`;

export const DescriptionTeam = styled.div`
  font-family: "Bahnschift Light", sans-serif;
  font-size: 20px;
  max-width: 80%;
  margin: 0;
  font-weight: 300;
  margin-top: 35px;
  margin-bottom: 30px;

  @media screen and (max-width: 640px) {
    margin-top: 10px;
    font-size: 15px;
    max-width: 90%;
    font-weight: 200;
    margin-right: 0px;
  }

  @media screen and (max-width: 320px) {
    margin-top: 10px;
    font-size: 15px;
  }
`;

export const ContainerTeamMember = styled.div`
  border: 10px solid white;
  box-sizing: border-box;
  padding: 20px;
  width: 300px;
  min-height: 300px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  @media (max-width: 1000px) {
    flex-wrap: 32%;
  }
  @media screen and (max-width: 640px) {
    width: 100%;
  }
`;

export const ContainerMembers = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  @media screen and (max-width: 640px) {
    flex-direction: column;
  }
`;

export const ContainerMissionLeft = styled.section`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 48px 0;
  width: 30%;

  @media (max-width: 640px) {
    padding: 0;
    flex-wrap: wrap;
    justify-content: center;
    width: 100%;
  }
`;

export const ContainerMissionRight = styled.section`
  justify-content: space-between;
  align-items: center;
  padding: 48px 0;
  width: 70%;
  @media (max-width: 640px) {
    padding: 0;
    flex-wrap: wrap;
    justify-content: center;
    width: 100%;
  }
`;

export const HeroText = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  flex: 0 1 55%;
  padding: 0 12px;

  @media (max-width: 640px) {
    order: 1;
    flex: 0 1 100%;
    align-items: center;
    text-align: center;
  }
`;

const HeroImgWrapper = styled.div`
  flex: 0 1 45%;

  @media (max-width: 640px) {
    order: 0;
    flex: 0 1 100%;
  }
`;

const HeroImg = styled.img`
  width: 100%;
  max-width: 450px;
`;

const ImageMember = styled.img`
  max-width: 100%;
  height: 97px;
  /* border-radius: 50%; */
`;

const ImageStep = styled.img`
  max-width: 100%;
  height: 80px;
  padding-top: 30px;
  @media (max-width: 640px) {
    margin-left: 0px;
    flex-direction: column;
    padding: 30px 0px;
  }
`;

export const ContainerButtons = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex: 0 1 100%;
  margin-top: 40px;

  @media (max-width: 640px) {
    margin-top: 10px;
    flex-direction: column;
  }
`;

const StyledButtonBlue = styled(Link)`
  color: white;
  background: ${colors.blue_agente_btc};
  text-decoration: none;
  font-family: Montserrat, sans-serif;
  font-size: 19px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 70px;
  font-weight: 600;
  padding: 15px 55px;
  margin-top: 10px;
  @media (max-width: 640px) {
    font-size: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 35px;
    width: 120px;
    height: 35px;
  }
`;

const StyledButtonYellow = styled(Link)`
  color: #3c4a5b;
  background: ${colors.yellow_agente_btc};
  text-decoration: none;
  font-family: Montserrat, sans-serif;
  font-size: 19px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 70px;
  font-weight: 600;
  padding: 15px 55px;
  margin-top: 10px;
  @media (max-width: 640px) {
    font-size: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 35px;
    width: 120px;
    height: 35px;
  }
`;

const StyledButtonYellowSec = styled(StyledButtonYellow)`
  border-radius: 8px;
`;

const StyledButtonYellowExt = styled.a`
  color: #3c4a5b;
  background: ${colors.yellow_agente_btc};
  text-decoration: none;
  font-family: Montserrat, sans-serif;
  font-size: 19px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 70px;
  font-weight: 600;
  padding: 15px 55px;
  margin-top: 10px;
  @media (max-width: 640px) {
    font-size: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 35px;
    width: 120px;
    height: 35px;
  }
`;

const StyledButtonYellowSecExt = styled(StyledButtonYellowExt)`
  border-radius: 8px;
  margin: auto;
`;

const StyledButtonTransparent = styled(Link)`
  background: trasparent;
  color: ${colors.dark_blue_agente_btc};
  text-decoration: none;
  font-family: Montserrat, sans-serif;
  font-size: 19px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 70px;
  font-weight: 600;
  padding: 15px 55px;
  margin-top: 1 0px;
  @media (max-width: 640px) {
    font-size: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 35px;
    width: 120px;
    height: 35px;
  }
`;

// Seccion Calculadora

const WrapperCalculatorSection = styled.div`
  width: 100%;
  max-width: 1200px;
  margin: auto;
  box-sizing: border-box;
  padding: 0 32px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justfify-content: center;
  @media (max-width: 900px) {
    flex-direction: column;
    padding: 0 24px;
  }
`;

const ContainerAboutSection = styled.div`
  width: 100%;
  padding-right: 250px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justfify-content: flex-start;
`;

const ContainerLogos = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding-top: 30px;
`;

// const ImageDetail = styled.img`
//   width: 80px;
//   height: 80px;
//   flex-shrink: 0;
//   margin-right: 10px;
// `;

const ImageBanks = styled.img`
  height: 60px;
  flex-shrink: 0;
`;

// const ContainerDetail = styled.div`
//   width: 100%;
//   display: flex;
//   flex-direction: row;
//   align-items: flex-start;
//   justfify-content: flex-start;
//   margin: 40px 0px;
// `;

const ContainerRight = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justfify-content: flex-start;
`;

// Generales

const ContainerSectionHeader = styled.div`
  width: 100%;
  text-align: center;
`;

const ContainerSectionBody = styled.div`
  width: 100%;
  padding-right: 250px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justfify-content: flex-start;
  text-align: center;
`;

// Waves

const SectionWave = styled.img`
  width: 110vw;
  display: flex;
  padding: 0px;
  margin-left: -5vw;
`;

const SectionGrayBg = styled.div`
  width: 100%;
  background-color: #eaf1f6;
`;

const SectionBlueBg = styled.div`
  width: 100%;
  background-color: #1a4bea;
  color: #ffffff !important;
  padding-top: 50px;
  padding-bottom: 60px;
`;

/* NUESTROS SERVICIOS */
const SectionTextCentered = styled.h4`
  text-align: center;
  color: white;
  font-family: "Bahnschift Light", sans-serif;
  font-size: 22px;
  font-weight: normal;
  @media (max-width: 700px) {
    font-size: 20px;
  }
  @media (max-width: 500px) {
    font-size: 15px;
  }
`;

/* PASARELA EN CRYPTO */
const ContainerDetail = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: ce;
  margin: 15px 0px;
  @media (max-width: 500px) {
    margin: 10px 0px;
  }
`;
const ImageDetail = styled.img`
  width: 80px;
  height: 80px;
  flex-shrink: 0;
  margin-right: 10px;
  @media (max-width: 500px) {
    width: 60px;
    height: 60px;
  }
`;
export default {
  ImageDetail,
  ContainerDetail,
  SectionTextCentered,

  AboutContainer,
  MissionContainer,
  ContainerMissionLeft,
  ContainerMissionRight,
  TaglineAbout,
  ContainerTextTeam,
  SubtaglineHome,
  HeroSection,
  HeroText,
  HeroImgWrapper,
  HeroImg,
  StyledButtonBlue,
  TeamContainer,
  StyledButtonYellow,
  StyledButtonYellowSec,
  StyledButtonTransparent,
  ContainerButtons,
  WrapperCalculatorSection,
  ContainerAboutSection,
  // ImageDetail,
  ImageBanks,
  // ContainerDetail,
  ContainerRight,
  ContainerSectionHeader,
  ContainerSectionBody,
  SectionWave,
  SectionGrayBg,
  SectionBlueBg,
  ContainerLogos,
  DescriptionTeam,
  ContainerTeamMember,
  ContainerMembers,
  ImageMember,
  TeamMemberName,
  TeamMemberDescription,
  TeamMemberPosition,
  StepContainer,
  ContainerStepLeft,
  ImageStep,
  StyledButtonYellowExt,
  StyledButtonYellowSecExt,
};
